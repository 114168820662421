var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"cl cl--light cl--float cl--jigsaw",staticStyle:{"min-width":"220px"}},[_c('div',{staticClass:"cl_panel",style:({
        bottom: '40px',
        'padding-bottom': '15px',
        display:_vm.hover?'block':'none'
      }),on:{"mouseover":_vm.handelMouseover,"mouseleave":_vm.handelMouseleave}},[_c('div',{staticClass:"cl_panel-placeholder"},[_vm._m(0),(_vm.loading)?_c('div',{staticClass:"cl_loadbox",staticStyle:{"border-radius":"2px"}},[_vm._m(1)]):_vm._e(),_c('div',{staticClass:"cl_top"},[_c('div',{staticClass:"cl_refresh",on:{"click":_vm.refresh}})])])]),_c('div',{staticClass:"cl_control",style:({
        height: '40px',
        'border-radius': '2px',
        borderColor:_vm.error?'#FF4D4F':_vm.success?'#52ccba':'',
        backgroundColor:_vm.error?'#FF4D4F':_vm.success?'#d2f4ef':''

      })},[_c('div',{staticClass:"cl_slide_indicator",staticStyle:{"height":"40px","border-radius":"2px"}}),_c('div',{staticClass:"cl_slider",style:({
          width: '40px',
          'border-radius': '2px',
          backgroundColor:_vm.error?'#FF4D4F':_vm.success?'#52ccba':_vm.hover?'#1991fa':'#ffffff'
        }),on:{"mouseup":function($event){$event.stopPropagation();return _vm.handleMouseUp.apply(null, arguments)},"mouseleave":_vm.handelMouseleave,"mousedown":_vm.handleMouseDown,"mouseover":_vm.handelMouseover}},[(_vm.success)?_c('div',{staticClass:"cl_slider__icon slider_success"}):(_vm.error)?_c('div',{staticClass:"cl_slider__icon slider_error"}):_c('div',{staticClass:"cl_slider__icon",class:_vm.hover?'slider_white':'slider_black'})]),_c('div',{staticClass:"cl_tips",style:({
          'line-height': '37px'
        })},[_c('span',{staticClass:"cl_tips__icon"}),_c('span',{staticClass:"cl_tips__text cl-fallback__tip"},[_vm._v(_vm._s(_vm.tip))]),_vm._m(2)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cl_bgimg",staticStyle:{"border-radius":"2px"}},[_c('img',{staticClass:"cl_bg-img",staticStyle:{"border-radius":"2px"},attrs:{"src":""}}),_c('img',{staticClass:"cl_jigsaw",attrs:{"src":""}}),_c('div',{staticClass:"cl_inference cl_inference--0",attrs:{"draggable":"true"}},[_c('img',{staticClass:"cl_inference__img",attrs:{"draggable":"false"}}),_c('span',{staticClass:"cl_inference__border"})]),_c('div',{staticClass:"cl_inference cl_inference--1",attrs:{"draggable":"true"}},[_c('img',{staticClass:"cl_inference__img",attrs:{"draggable":"false"}}),_c('span',{staticClass:"cl_inference__border"})]),_c('div',{staticClass:"cl_inference cl_inference--2",attrs:{"draggable":"true"}},[_c('img',{staticClass:"cl_inference__img",attrs:{"draggable":"false"}}),_c('span',{staticClass:"cl_inference__border"})]),_c('div',{staticClass:"cl_inference cl_inference--3",attrs:{"draggable":"true"}},[_c('img',{staticClass:"cl_inference__img",attrs:{"draggable":"false"}}),_c('span',{staticClass:"cl_inference__border"})]),_c('div',{staticClass:"cl_inference cl_inference--4",attrs:{"draggable":"true"}},[_c('img',{staticClass:"cl_inference__img",attrs:{"draggable":"false"}}),_c('span',{staticClass:"cl_inference__border"})]),_c('div',{staticClass:"cl_inference cl_inference--5",attrs:{"draggable":"true"}},[_c('img',{staticClass:"cl_inference__img",attrs:{"draggable":"false"}}),_c('span',{staticClass:"cl_inference__border"})]),_c('div',{staticClass:"cl_inference cl_inference--6",attrs:{"draggable":"true"}},[_c('img',{staticClass:"cl_inference__img",attrs:{"draggable":"false"}}),_c('span',{staticClass:"cl_inference__border"})]),_c('div',{staticClass:"cl_inference cl_inference--7",attrs:{"draggable":"true"}},[_c('img',{staticClass:"cl_inference__img",attrs:{"draggable":"false"}}),_c('span',{staticClass:"cl_inference__border"})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cl_loadbox__inner"},[_c('div',{staticClass:"cl_loadicon"}),_c('span',{staticClass:"cl_loadtext"},[_vm._v("加载中...")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"cl_tips__answer hide"},[_c('span',{staticClass:"cl_tips__point"}),_c('img',{staticClass:"cl_tips__img"})])
}]

export { render, staticRenderFns }