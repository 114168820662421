import axios from 'axios'
import {Message} from "element-ui";
import router from "../router/index"
import store from "../store/index"
import config from "../../public/config";


let reg = RegExp('token' + '=([^;]+)');
let arr = []
if(document.cookie.match(reg)){
  arr = document.cookie.match(reg)
}
const instance = axios.create({
  baseURL:config.apiUrl,
  timeout:1000000,
  headers:{
    "Content-Type":"application/json;charset=UTF-8",
    "Buyer-User-Token-Admin":arr[1]
  }
})
//请求拦截器
instance.interceptors.request.use(
  config=>{
    store.state.isLoading = true
    return config
  },
  err => {
    return Promise.reject(err)
  })
//响应拦截器
instance.interceptors.response.use(
  res => {
// 可以在这里判断http状态码
    if(res.data.code == "1"){
      store.state.isLoading = false
      return res.data
    }else if(res.data.code == "404"){

    }else if(res.data.code == "20001"){
      document.cookie = 'token' + "=" + "; expires=Thu, 01-Jan-70 00:00:01 GMT";//删除cookie删除token
    }else if(res.data.code == "20002"){

    }else if(res.data.code == "20003"){


    }else if(res.data.code == "20004"){


    }else if(res.data.code == "20005"){

    }else if(res.data.code == "20007"){
      Message({message:"用户已在别处登录，谨防密码泄露",type:'error'})
      document.cookie = 'token' + "=" + "; expires=Thu, 01-Jan-70 00:00:01 GMT";//删除cookie删除token
    }else if(res.data.code == "20008"){
      document.cookie = 'token' + "=" + "; expires=Thu, 01-Jan-70 00:00:01 GMT";//删除cookie删除token
      location.reload()
    }else if(res.data.code == "40001"){

    }else if(res.data.code == "70001"){

    }else if(res.data.code == "80001"){

    }
    if(res.data.code != "20001" && res.data.code != "20008" && res.data.code != "20007"){
      Message({message:res.data.info || res.data.msg,type:"error"})
    }
    return res.data
  },
  err => {
    Message({message:'服务器出现问题，请刷新后重试',type:"error"})
    setTimeout(()=>{
      store.state.isLoading = false
    },2000)
    return Promise.reject(err)
  })
export default instance
