  <template>
  <div>
    <Header></Header>
    <div class="banner">
      <div class="mask">
        <div class="ya315-container">
          <div class="d-b-title">关于食安链家</div>
          <div class="d-b-info">深耕农业领域，打造集上游农业种养殖基地到农批市场的“农互通”、城市内从农批到终端的“商互通”和终端商户经营方的农贸市场商户的“云管家”于一体的农产品产业链管理平台。</div>
        </div>
      </div>
    </div>
    <div class="info-page">
      <div class="d-i-title">食安链家</div>
      <div class="ya315-container" style="color:#999999;font-size: 20px;">
        平台整合供应商、物流商、品牌商和终端生鲜门店、餐饮门店和养殖场资源，以自有商品、B2B支付、终端交易和C端聚合支付为支撑、以品牌商和终端业务流通和面向下游销售管理为基础，通过资源整合，打造统一供应平台，对终端客户提供商品供应和营销服务，并为食品农产品的安全追溯打下坚实基础。
      </div>
    </div>
    <div class="advantage-page">
      <div class="d-i-title">食安链家产业</div>
      <div class="ya315-container" style="text-align: center;">
        <img src="../../assets/img/img-salj.png" alt="">
      </div>
    </div>
    <div>
      <div class="d-i-title">强大服务体系</div>
      <div class="ya315-container" style="display: flex;justify-content: space-between;">
        <div class="about-item">
          <div>SAAS体系</div>
          <div>为企业提供全方位的软件服务</div>
          <a href="">了解SAAS体系</a>
        </div>
        <div class="about-item">
          <div>解决方案</div>
          <div>提供支持多种业务场景解决方案</div>
          <a href="">了解解决方案</a>
        </div>
        <div class="about-item">
          <div>云市场</div>
          <div>提供软件和服务需求的食安云市场</div>
          <a href="">了解云市场</a>
        </div>
        <div class="about-item">
          <div>帮助文档</div>
          <div>丰富的官方文档，助企业数字化运作</div>
          <a href="">了解帮助文档</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name:'djpt',
  data(){
    return{

    }
  }
}
</script>

<style scoped>
  .banner{height:500px;background:#3A3D41 url(../../assets/img/banner-salj.png);background-size:cover;position: relative}
.mask{background:rgba(0,0,0,.4);width:100%;height:100%;position: absolute}
.d-b-title{font-size: 48px;color:#fff;padding-top:170px;margin-left: 360px;}
.d-b-info{font-size: 18px;color:#ffffff;margin-top:36px;width:850px;margin-left: 360px;}
.d-i-title{font-size: 36px;text-align: center;margin-top:100px;margin-bottom:72px;}
.about-item{width:280px;height:306px;box-shadow:0 0 5px rgba(0,0,0,.1);background: #ffffff;margin-bottom:185px;}
.about-item>div:first-child{line-height: 83px;padding-left:24px;font-size: 24px;color:#333333;}
.about-item>div:nth-child(2){color:#999999;font-size: 14px;padding-left:24px;margin-bottom: 12px;}
.about-item>a{color:#2690FD;font-size: 14px;padding-left:24px;}
</style>
