import { render, staticRenderFns } from "./case.vue?vue&type=template&id=03b1d6ce&scoped=true"
import script from "./case.vue?vue&type=script&lang=js"
export * from "./case.vue?vue&type=script&lang=js"
import style0 from "./case.vue?vue&type=style&index=0&id=03b1d6ce&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03b1d6ce",
  null
  
)

export default component.exports