<template>
  <div id="scrollPage">
    <Header></Header>
    <div class="top-height"></div>
    <div class="banner">
      <div class="mask">

      </div>
    </div>
    <div :class="isFixed?'fd':''" class="nav">
      <div class="ya315-container">
        <div @click="anchor(0)" :class="anchorNum==0?'cur':''">为什么加入我们</div>
        <div @click="anchor(1)" :class="anchorNum==1?'cur':''">我们的合作体系</div>
        <div @click="anchor(2)" :class="anchorNum==2?'cur':''">重点合作计划</div>
        <div @click="anchor(3)" :class="anchorNum==3?'cur':''">伙伴展示</div>
      </div>
    </div>
    <div>
      <div id="page1" class="common-title">合作优势</div>
      <div class="page1 ya315-container">
        <div>
          <img class="page1-img" src="../../assets/img/page1-img1.png" alt="">
          <div class="page1-title">领先的技术能力</div>
          <div class="page1-info">依托于华为云分布式架构方式，将食安链家各平台分别管理，对外输出，赋能管理服务云平台。</div>
        </div>
        <div>
          <img class="page1-img" src="../../assets/img/page1-img2.png" alt="">
          <div class="page1-title">开放生态</div>
          <div class="page1-info">提供集成与被集成的开放生态合作模式，与伙伴优势互补、联合创新，共享商机、共生共赢。</div>
        </div>
        <div>
          <img class="page1-img" src="../../assets/img/page1-img3.png" alt="">
          <div class="page1-title">丰富市场资源</div>
          <div class="page1-info">联合政府食品安全监管部门，对本地企业进行食品安全管理，并助力合作企业拓展市场。</div>
        </div>
        <div>
          <img class="page1-img" src="../../assets/img/page1-img4.png" alt="">
          <div class="page1-title">销售赋能</div>
          <div class="page1-info">提供专属技术咨询及售前、方案、交付等支持，为合作伙伴重要项目保驾护航。</div>
        </div>
      </div>
    </div>
    <div style="background:#F6F8FF;height:752px;padding-top:2px;">
      <div id="page2" class="common-title">合作体系</div>
      <div class="page2 ya315-container">
        <div class="page2-nav">
          <div :class="page2Nav==1?'cur':''" @click="page2Nav=1">渠道供应伙伴 <div class="line"></div> </div>
          <div :class="page2Nav==2?'cur':''" @click="page2Nav=2">交付服务伙伴 <div class="line"></div> </div>
          <div :class="page2Nav==3?'cur':''" @click="page2Nav=3">产品及方案合作 <div class="line"></div> </div>
          <div :class="page2Nav==4?'cur':''" @click="page2Nav=4">云市场合作 <div class="line"></div> </div>
        </div>
        <div class="page2-content">
          <div>
            <div class="page2-img"><img src="../../assets/img/c-page2-img1.png" alt=""></div>
            <div class="page2-title">农产品供应商</div>
            <div class="page2-desc">初级农产品和加工农产品，包含种植业、畜牧业、渔业产品，如谷物、油脂、农业原料、畜禽等。二次加工如消毒奶、分割肉、冷冻肉、食用油、饲料等。</div>
            <div class="page2-btn1 cooperation-btn blue">立即加入</div>
            <div class="page2-btn2 cooperation-btn write">查看详情</div>
          </div>
          <div>
            <div class="page2-img"><img src="../../assets/img/c-page2-img2.png" alt=""></div>
            <div class="page2-title">食品供应商</div>
            <div class="page2-desc">初级农产品和加工农产品，包含种植业、畜牧业、渔业产品，如谷物、油脂、农业原料、畜禽等。二次加工如消毒奶、分割肉、冷冻肉、食用油、饲料等。</div>
            <div class="page2-btn1 cooperation-btn blue">立即加入</div>
            <div class="page2-btn2 cooperation-btn write">查看详情</div>
          </div>
          <div>
            <div class="page2-img"><img src="../../assets/img/c-page2-img3.png" alt=""></div>
            <div class="page2-title">硬件供应商</div>
            <div class="page2-desc">SAAS系统场景中所涉及的收银机、扫码枪、扫码器、钱箱及打印机等</div>
            <div class="page2-btn1 cooperation-btn blue">立即加入</div>
            <div class="page2-btn2 cooperation-btn write">查看详情</div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div id="page3" class="common-title">重点计划</div>
      <div class="page3 ya315-container">
        <div>
          <div class="page3-title">民权新农汇农批商贸城招商计划</div>
          <div class="page3-desc">通过食安链家平台以及区域农产品批发市场的优势赋能超市、餐饮品牌企业以最低的成本快速发展市场，为超市、餐饮品牌企业提供服务内容</div>
          <a class="page3-link" href="">立即加入</a>
        </div>
        <div>
          <div class="page3-title">搭建政府产品追溯体系计划</div>
          <div class="page3-desc">建立产品追溯标准体系、信息安全体系、运营管理体系、绩效评估体系、组织保障体系，逐项推进分步实施，打造追溯示范区并快速复制和推广</div>
          <a class="page3-link" href="">立即加入</a>
        </div>
      </div>
    </div>
    <div style="background:#F6F8FF;padding-top:1px;height:468px;">
      <div id="page4" class="common-title">优秀伙伴展示</div>
      <div class="page4 ya315-container">
        <img src="../../assets/img/c-page4-1.png" alt="">
        <img src="../../assets/img/c-page4-2.png" alt="">
        <img src="../../assets/img/c-page4-3.png" alt="">
        <img src="../../assets/img/c-page4-4.png" alt="">
        <img src="../../assets/img/c-page4-5.png" alt="">
        <img src="../../assets/img/c-page4-6.png" alt="">
        <img src="../../assets/img/c-page4-7.png" alt="">
      </div>
    </div>
    <div>
      <div id="page5" class="common-title">精品案例</div>
      <div class="page5 ya315-container">
        <div class="page5-title">河南省标准化研究院</div>
        <div class="page5-desc">通过食安链家平台以及区域农产品批发市场的优势赋能超市、餐饮品牌企业以最低的成本快速发展市场，为超市、餐饮品牌企业提供服务内容</div>
        <div class="page5-left">
          <div class="p5-title">业务需求/痛点</div>
          <div class="p5-item">1.搭建产品追溯体系</div>
          <div class="p5-item">2.农产品食品追溯质量查询</div>
          <div class="p5-item">3.共建产品追溯示范区</div>
        </div>
        <div class="page5-arrow"><img src="../../assets/img/c-page5-arrow.png" alt=""></div>
        <div class="page5-right">
          <div class="p5-title">相关产品/解决方案</div>
          <div class="p5-item">1.农产品食品追溯质量查询</div>
          <div class="p5-item">1.农产品食品追溯质量查询</div>
        </div>
        <div class="page5-arrow-btm" :class="'left'+page5Index"></div>
      </div>
      <div class="ya315-container" style="width:1076px;display: flex;justify-content: space-between;margin-bottom:53px;">
        <img @mouseenter="page5Index=1" src="../../assets/img/c-page4-4.png" alt="">
        <img @mouseenter="page5Index=2" src="../../assets/img/c-page4-3.png" alt="">
        <img @mouseenter="page5Index=3" src="../../assets/img/c-page4-6.png" alt="">
        <img @mouseenter="page5Index=4" src="../../assets/img/c-page4-7.png" alt="">
      </div>
    </div>
    <div style="height:416px;background:#F6F8FF;padding-top:1px;">
      <div id="page6" class="common-title">申请流程</div>
      <div class="page6 ya315-container">
        <img src="../../assets/img/c-page6.png" alt="">
      </div>
      <div class="cooperation-btn blue" style="margin:0 auto;">立即加入</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "index",
    data(){
      return {
        anchorNum:0,
        page2Nav:1,
        page5Index:1,
        isFixed:false
      }
    },
    watch: {
      $route(to, form) {
        console.log(to.query.type)
        this.anchor(to.query.type)
      }
    },
    methods:{
      anchor(n){
        this.anchorNum = n;
        let top = 0
        if(n==0){
          top = $('#page1').offset().top-72-60
        }else if(n==1){
          top = $('#page2').offset().top-72-60
        }else if(n==2){
          top = $('#page3').offset().top-72-60
        }else{
          top = $('#page4').offset().top-72-60
        }
        $('html').scrollTop(top)
      }
    }
  }
</script>

<style scoped>
  .cooperation-btn{cursor:pointer;width:130px;height:36px;border:1px solid #707070;text-align:center;border-radius: 8px;box-sizing: border-box;line-height: 34px;}
  .blue{color:#fff;background:#2690FD;border-color:#2690FD;box-shadow: 0 0 6px rgba(148, 200, 254, .88)}
  .write{color:#666666;border-color:#707070;}
  .top-height{height:60px;}
  .banner{width:100%;height:500px;background-image: url(../../assets/img/cooperation-banner.png);background-size: 100% 100%;position: relative}
  .mask{background:rgba(0,0,0,.4);width:100%;height:100%;position: absolute}
  .nav{height:54px;width:100%;background:#fff;overflow: hidden;}
  .nav.fd{position:fixed;top:60px;z-index: 10;}
  .nav>div{display: flex;justify-content: center;}
  .nav>div>div{width:120px;margin:0 80px;height:54px;border-bottom:2px solid transparent;line-height: 54px;font-size: 16px;color:#52565B;text-align: center;cursor: pointer;}
  .nav>div>div.cur{color:#1890FF;border-bottom-color: #1890FF;}
  .common-title{color:#52565B;font-size: 30px;margin-top:64px;text-align: center;}
  .common-title-md{color:#121A25;font-size: 26px;margin-top:100px;text-align: center;margin-bottom:24px;}
  .common-desc{color:#52565B;font-size: 16px;margin-top:39px;line-height: 36px;letter-spacing: 1px;}
  .common-desc-sm{margin-bottom:12px;color:#707070;font-size: 16px;text-align: center;}
  .page1{margin-top:42px;}
  .page1>div{width:600px;height:188px;float:left;position:relative;}
  .page1:after{display: table;content:'';clear:both;}
  .page1-img{width:48px;height:48px;position:absolute;top:30px;left:32px;}
  .page1-title{position:absolute;top:40px;left:112px;font-size: 20px;color:#121A25;font-weight: 600;}
  .page1-info{position:absolute;top:88px;left:112px;width:450px;}
  .page2-nav{width:620px;display: flex;justify-content: space-between;margin:32px auto;}
  .page2-nav>div{cursor: pointer;font-size: 20px;color:#121A25;}
  .page2-nav>div.cur>.line{width:84px;border-top:1px solid #2C2C2C;margin:7px auto 0;}
  .page2-content{display: flex;justify-content: space-between;}
  .page2-content>div{width:380px;height:460px;background:#ffffff;position:relative;}
  .page2-content>div>div{position:absolute;}
  .page2-img{width:60px;height:60px;top:56px;left:160px;}
  .page2-title{font-size: 18px;color:#2C2C2C;text-align: center;width:100%;top:137px;}
  .page2-desc{width:330px;height:166px;top:190px;left:25px;color:#666666;line-height: 28px;}
  .page2-btn1{bottom:51px;left:42px;}
  .page2-btn2{bottom:51px;right:42px;}
  .page3{display: flex;justify-content: space-between;margin-top:60px;}
  .page3>div{width:580px;height:188px;border:1px solid #C2C5CC;margin-bottom:80px;}
  .page3-title{padding-top:26px;padding-left:31px;color:#2C2C2C;font-size: 18px;}
  .page3-desc{color:#666666;padding-left:31px;margin-top:18px;width:507px;font-size: 14px;line-height: 28px;margin-bottom:14px;}
  .page3-link{color:#2690FD;margin-left:31px;font-size: 14px;line-height: 28px;}
  .page4{display: flex;flex-wrap:wrap;margin-top:50px;}
  .page4>img{margin-right:28px;}
  .page4>img:first-child,.page4>img:nth-child(5){margin-left:58px;}
  .page4>img:nth-child(4){margin-right:0;}
  .page5{height:499px;border:1px solid #DDE0E7;margin-top:53px;position:relative;border-radius: 8px;box-shadow: 0 0 18px rgba(122, 122, 122, .1);margin-bottom: 50px;}
  .page5>div{position:absolute;}
  .page5-title{font-size: 24px;color:#2C2C2C;font-weight: bold;top:39px;left:60px;}
  .page5-desc{color:#666666;font-size: 14px;left:60px;top:96px;width:1083px;}
  .page5-left{width:457px;height:235px;border:1px solid #DDE0E7;border-radius: 8px;background:#FEFEFE;bottom:80px;left:98px; }
  .page5-right{border:1px solid #58626E;background:#F7FBFE;width:457px;height:235px;bottom:80px;right:98px;border-radius: 8px;}
  .page5-arrow{left:50%;bottom:180px;margin-left:-29px;}
  .page5-arrow-btm{width:25px;height:25px;background:#fff;border-right:1px solid #DDE0E7;transform:rotate(45deg);bottom:-13px;left:170px;border-bottom:1px solid #DDE0E7;}
  .page5-arrow-btm.left1{left:170px;}
  .page5-arrow-btm.left2{left:450px;}
  .page5-arrow-btm.left3{left:730px;}
  .page5-arrow-btm.left4{left:1010px;}
  .p5-title{color:#2C2C2C;font-size: 20px;line-height: 80px;margin-left:30px;}
  .p5-item{color:#666666;font-size: 14px;margin-left:37px;margin-bottom:10px;}
  .page5-right .p5-title,.page5-right .p5-item{color:#666666;}
  .page6{margin-top: 55px;text-align: center;margin-bottom:86px;}
</style>
