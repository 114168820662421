<template>
  <div id="shop_car">
    <div style="width: 1400px;display: flex;justify-content: space-between;margin: 0 auto;align-items: center;">
      <div style="height: 70px;display: flex;align-items: center;">
        <img style="margin-top:9px;" width="340" height="36" src="../assets/img/logo-header.svg" alt="食安链家">
        <div style="font-weight: bold;font-size: 20px;padding-top: 10px;margin-left: 50px;color: #1890ff;">购物车</div>
      </div>
<!--      <el-button style="margin-left: 20px;margin-top: 10px;" type="primary">返回</el-button>-->
      <div @click="$router.go(-1)" style="display: flex;align-items: center;cursor: pointer;">
        <img width="20" src="../assets/img/backIcon.png" alt="">
        <span style="font-size: 14px;color: #1890FF;">返回</span>
      </div>
    </div>
    <section class="car_main">
      <!--顶部列表-->
      <div class="car_head">
        <ul class="car_top">
          <li class="car_check_all">
            <input style="width: 20px;height: 20px;" type="checkbox" id="check_all" v-model="checked_all" @click="all_products_checked"/>
            <label for="check_all" style="margin-left: 10px;">全选</label>
          </li>
          <li class="car_information">商品信息</li>
          <li class="car_price">单价</li>
          <li class="car_space">规格</li>
          <li class="car_time">时长</li>
          <li class="car_number">数量</li>
          <li class="car_amount">金额</li>
          <li class="car_operation">操作</li>
        </ul>
      </div>
      <!--购物车详情展示-->
      <template>
        <div v-for="(shop,shop_index) in shopList">
          <!--当店铺下面有已购买商品的时候显示该店铺和商品信息-->
          <div>
            <!--商品详细标题-->
            <div class="shop_info">
              <ul>
                <li><input style="width: 20px;height: 20px;" type="checkbox" v-model="shop.all_checked" @click="shop_all_check(shop_index)"></li>
                <li><span style="margin-left: 10px;">店铺：</span></li>
                <li><a :href="shop.href">{{shop.storeName}}</a></li>
              </ul>
            </div>
            <div v-for="(product,product_index) in shop.cartDtoList" class="product">
              <div class="product_details">
                <!--商品是否选中-->
                <div class="product_check">
                  <input style="width: 20px;height: 20px;" type="checkbox" v-model="product.product_checked"
                         @click="product_checked_click(shop_index,product_index)"/>
                </div>
                <!--商品图片-->
                <div style="margin-left: 10px;" class="product_img">
                  <img width="30" :src="JSON.parse(product.goodsImgs)[0]" style="width: 100px;height: 100px"/>
                </div>
                <!--商品名称-->
                <div class="product_shop_info">
                  <div class="product_shop_name">
                    <a :href="product.href">{{product.goodsTitle}}</a>
<!--                    goodsTitle-->
                  </div>
                </div>
                <div></div>
                <!--商品价格-->
                <div class="product_price">
                  <div class="product_price_current">
                    ￥{{product.price/100}}
                  </div>
                </div>
                <div class="product_space">111</div>
                <div class="product_time">
                  <div>
                    <span v-show="product.dateUnit == 1">天</span>
                    <span v-show="product.dateUnit == 2">月</span>
                    <span v-show="product.dateUnit == 3">年</span>
                    <span v-show="product.dateUnit == 4">长期</span>
                  </div>
                </div>
                <!--商品数量-->
                <div class="product_number">
                  <div class="product_number_content">
                    <el-input-number v-model="product.quantity" @change="handleChange(product)" size="mini" :min="1"></el-input-number>
                  </div>
                </div>
                <!--金额统计-->
                <div class="product_amount">
                  <div class="product_total_price">
                    ￥{{ product.price/100 * product.quantity }}
                  </div>
                </div>

                <!--操作选项-->
                <div class="product_operation">
                  <div>
                    <button @click="domDel(product)">删除</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <!--底部页面-->
      <div style="width: 100%;background: white;position: fixed;bottom: 0;right: 0;left: 0;z-index: 1000;box-shadow: 0 -6px 16px 0 rgba(0,0,0,0.08);">
        <div v-show="shopList.length>0" style="width: 70%;height: 100px;margin: 0 auto;display: flex;justify-content: space-between;line-height: 100px;">
          <div>
            <el-popconfirm @confirm="selectDel" title="确定要删除所选中的商品吗？">
              <el-button slot="reference" type="text" style="color: red;">批量删除</el-button>
            </el-popconfirm>
            <span style="margin-left: 50px;">已选商品</span><span style="font-weight: bold;font-size: 18px;color: red;padding: 10px;">{{ shopCount }}</span><span>件</span>
          </div>
          <div style="display: flex;">
            <span style="margin-left: 50px;">合计（不含运费）：</span><span style="font-weight: bold;color: red;font-size: 20px;margin-right: 20px;">￥{{ count | priceFilter }}</span>
            <div style="width: 100px;height: 100px;"><el-button @click="goOrder">结算</el-button></div>
          </div>
        </div>
      </div>
      <div style="height: 100px;"></div>

    </section>
  </div>
</template>

<script>
  export default {
    name: "cart",
    data(){
      return {
        shopList:[],
        sum:0,
        list:[],
        options:[
          {label:"1个月",value:"month1"},
          {label:"6个月",value:"month6"},
          {label:"1年",value:"year1"},
        ],
        options1:[
          {label:"单次",value:"singleTime"},
        ],
        title:"1111",
        timer:null,
        state:false,
        checked_all: false,
        count:0,
        shopCount:0,
      }
    },
    filters:{
      price(val){
        if(!isNaN(val)){return val.toFixed(2)}else{return val}
      },
    },
    computed:{
      // 购物车全选
      // all_checked() {
      //   for (let i = 0; i < this.shopList.length; i++) {
      //     if (!this.shopList[i].all_checked) {
      //       return false
      //     }
      //   }
      //   return true
      // },
    },
    filters: {
      // 金额显示过滤 两位小数点，不足补0
      priceFilter(value) {
        var value = Math.round(parseFloat(value) * 100) / 100;
        var xsd = value.toString().split(".");
        if (xsd.length == 1) {
          value = value.toString() + ".00";
          return value;
        }
        if (xsd.length > 1) {
          if (xsd[1].length < 2) {
            value = value.toString() + "0";
          }
          return value;
        }
      }
    },
    mounted() {
      this.userList()
      this.total_amount()
      this.selected_products()
    },
    methods:{
      userList(){
        this.http.cartList().then(res=>{
          if(res.code == "1"){
            this.shopList = res.data
            res.data.forEach((item,index)=>{
              item.all_checked = false
              item.cartDtoList.forEach((v,i)=>{
                v.product_checked = false
              })
            })
          }else if(res.code == "20001"){
            this.$router.push({path:"/shop"})
          }
        })
      },
      handleChange(row){
        if (this.timer) {
          clearTimeout(this.timer);
        }
        this.timer = setTimeout(() => {
          let str = `?id=${row.id}&num=${row.quantity}`
          this.http.updateNum(str).then(res=>{
            if(res.code == "1"){
              this.total_amount()
            }
          })
        }, 100);
      },
      goOrder(){
        let list = []
        let arr1 = []
        let shopList = []
        //拿到所有的商品
        this.shopList.forEach((item,index)=>{
          item.cartDtoList.forEach((item1,index1)=>{
            list.push(item1)
          })
        })
        //循环所有的商品，拿到已选中的商品
        list.forEach((item2,index2)=>{
          if(item2.product_checked){
            arr1.push(item2)
          }
        })
        //循环已选到的商品
        for (let a in arr1){
          let str = 0
          //
          for (let i in shopList){
            if(shopList[i].sellerId == arr1[a].sellerId){
              shopList[i].shops.push(arr1[a])
              str = 1
              continue
            }
          }
          if(str == 1){
            continue
          }
          for (let b in this.shopList){
            if(arr1[a].sellerId == this.shopList[b].sellerId){
              this.shopList[b].shops = []
              this.shopList[b].sellerId = arr1[a].sellerId
              this.shopList[b].shops.push(arr1[a])
              shopList.push(this.shopList[b])
              continue
            }
          }
        }
        console.log(shopList)
        if(shopList.length==0){
          this.$message({message:"请选择需要结算的商品",type:"warning"})
        }else{
          localStorage.setItem("order",JSON.stringify(shopList))
          this.$router.push({path:"/confirmOrder"})
        }
      },
      selectDel(){
        let arr = []
        this.shopList.forEach((item,index)=>{
          item.cartDtoList.forEach((item1,index1)=>{
            if(item1.product_checked){
              arr.push(item1.id)
            }
          })
        })
        if(arr.length > 0){
          this.http.delCart('?ids=' + arr.toString()).then(res=>{
            if(res.code == "1"){
              this.$message({message:"删除成功",type:"success"})
              this.userList()
            }
          })
        }else{
          this.$message({message:"请选择要删除的商品",type:"warning"})
        }
        // if(this.list.length>0){
        //   let arr = []
        //   this.list.forEach((item,index)=>{
        //     arr.push(item.id)
        //   })
        //   this.http.delCart('?ids=' + arr.toString()).then(res=>{
        //       if(res.code == "1"){
        //         this.$message({message:"删除成功",type:"success"})
        //         this.userList()
        //       }
        //   })
        // }else{
        //   this.$message({message:"请选择要删除的商品",type:"warning"})
        // }
      },
      domDel(item){
        this.http.delCart('?ids=' + item.id).then(res=>{
            if(res.code == "1"){
              this.$message({message:"删除成功",type:"success"})
              this.userList()
            }
        })
      },
      // 全选
      all_products_checked() {
        this.checked_all = !this.checked_all
        this.shopList.forEach((item,index)=>{
          item.all_checked = this.checked_all
          item.cartDtoList.forEach((item1,index1)=>{
            item1.product_checked = this.checked_all
          })
        })
        this.total_amount()
        this.selected_products()
        this.$forceUpdate();
      },
      // 店铺全选
      shop_all_check(shop_index) {
        let shop = this.shopList[shop_index]
        shop.all_checked = !shop.all_checked
        shop.cartDtoList.forEach((item,index)=>{
          item.product_checked = shop.all_checked
        })
        for (let i = 0; i < this.shopList.length; i++) {
          if (!this.shopList[i].all_checked) {
            this.checked_all = false
            this.total_amount()
            this.selected_products()
            this.$forceUpdate();
            return
          }
        }
        this.checked_all = true
        this.total_amount()
        this.selected_products()
        this.$forceUpdate();
      },
      // 商品选中
      product_checked_click(shop_index, product_index) {
        let product = this.shopList[shop_index].cartDtoList[product_index]
        product.product_checked = !product.product_checked
        if(product.product_checked){
          this.shopList.forEach((item,index)=>{
            if(item.sellerId == product.sellerId){
              this.list.push(item)
              this.list.forEach((item1,index1)=>{
                if(item1.sellerId == product.sellerId){
                  // item1.cartDtoList = []
                  item1.shops = []
                  item1.shops.push(product)
                }
              })
            }
          })
        }
        // 检测是否该店铺内的商品全选
        for(let  i = 0; i < this.shopList[shop_index].cartDtoList.length; i++){
          if (!this.shopList[shop_index].cartDtoList[i].product_checked) {
            this.shopList[shop_index].all_checked = false
            this.checked_all = false
            this.total_amount()
            this.selected_products()
            this.$forceUpdate();
            return;
          }
        }
        this.shopList[shop_index].all_checked = true
        this.total_amount()
        this.selected_products()
        this.$forceUpdate();
        // 检测购物车内的商品是否全部选中
        for (let i = 0; i < this.shopList.length; i++) {
          if (!this.shopList[i].all_checked) {
            this.checked_all = false
            this.total_amount()
            this.selected_products()
            this.$forceUpdate();
            return
          }
        }
        this.checked_all = true
        this.total_amount()
        this.selected_products()
        this.$forceUpdate();
      },
      // 购买商品总金额
      total_amount() {
        let total_price = 0.00
        this.shopList.forEach((item,index)=>{
          item.cartDtoList.forEach((item1,index1)=>{
            if(item1.product_checked){
              total_price += item1.quantity * Number(item1.price/100)
            }
          })
        })
        this.count = total_price
      },
      // 已选择商品数目
      selected_products() {
        let selected_products = 0
        this.shopList.forEach((item,index)=>{
          item.cartDtoList.forEach((item1,index1)=>{
            if(item1.product_checked){
              selected_products++
            }
          })
        })
        this.shopCount = selected_products
      },
    },
  }
</script>

<style scoped>
  .content{
    width: 80%;
    margin: 0 auto;
  }

  .my_table >>> .el-table__row>td{
    /* 去除表格线 */
    border: none;
  }
  .el-table .warning-row {
    background: linear-gradient(to right,#fba721, #fcce68, #faefb4) !important;
  }

  .el-table .success-row {
    background: linear-gradient(to right,#fba721, #fcce68, #faefb4) !important;
  }
  .navHover:hover{
    color: #1890FF;
  }
  .thTitle{
    width: 1400px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
  .thTitle>div{
    width: 200px;
    height: 30px;
    line-height: 30px;
    font-weight: bold;
  }
  .shopList{
    width: 1400px;
    display: flex;
    justify-content: space-between;
    background: pink;
  }
  .shopList>div{
    width: 190px;
    height: 100px;
  }
   .car_main {
     position: relative;
     width: 1400px;
     margin: 0px auto;
     padding: 0px 0px;
     min-height: 250px;
   }

  .car_head {
    width: 100%;
    height: 45px;
    line-height: 45px;
    color: #3c3c3c;
    font-size: 14px;
    padding: 0px;
    border-bottom: 1px solid #DCDCDC;
  }

  .car_top {
    width: 100%;
    height: 50px;
  }

  ul {
    list-style: none;
  }

  li {
    float: left;
    text-align: left;
  }

  .car_check_all {
    position: relative;
    width: 10%;
    left: -20px;
  }

  .car_check_all input[type="checkbox"] {
  }

  .car_check_all label {
  }

  .car_information {
    width: 27%;
  }

  .car_price {
    width: 10%;
  }
  .car_space{
    width: 10%;
  }
  .car_time{
    width: 10%;
  }

  .car_number {
    width: 10%;
  }

  .car_amount {
    width: 10%;
  }

  .car_operation{
    width: 10%;
  }
  .car_foot_bar {
    position: absolute;
    width: 100%;
    height: 45px;
    font-size: 14px;
    color: #3c3c3c;
    margin-top: 15px;
  }

  .car_foot_settle {
    float: right;

  }

  .piece {
    float: left;
    height: 45px;
    line-height: 45px;
  }

  .totalMoney {
    float: left;
    margin-left: 20px;
    height: 45px;
    line-height: 45px;
  }

  .settle_accounts {
    float: left;
    margin-left: 20px;
    height: 45px;
    line-height: 45px;
  }

  .piece_num {
    color: red;
  }

  .total_text {
    color: red;
    font-size: 18px;
  }

  .settle_button {
    width: 100px;
    height: 45px;
    line-height: 45px;
    border: none;
    outline: none;
    background-color: red;

  }

  .settle_button:disabled {
    background-color: #eeefff;
  }

  .shop_info {
    width: 100%;
    height: 20px;
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 12px;
    align-items: center;
    display: flex;
  }


  .shop_info li a {
    text-decoration: none;
    margin-right: 10px;
    color: #3c3c3c;
  }

  .shop_info li a:hover {
    color: red;
    text-decoration: underline;
  }


  .product {
    width: 100%;
    border: 1px solid #DCDCDC;
    background: #F5F5F5;
  }

  .product_details {
    width: 100%;
    height: 145px;
    padding-left: 20px;
  }

  .product_check {
    float: left;
    margin-top: -8px;
    padding-top: 20px;
  }

  .product_img {
    float: left;
    padding-top: 20px;
  }

  .product_shop_info {
    height: 100px;
    float: left;
    padding-left: 10px;
    position: relative;
    width: 350px;
    padding-right: 20px;
    padding-top: 20px;
  }

  .product_shop_name {
    font-size: 12px;
    text-align: left;
  }

  .product_shop_name a {
    text-decoration: none;
    letter-spacing: 2px;
    color: #3c3c3c;
  }

  .product_price {
    width: 150px;
    float: left;
    padding-top: 20px;
    padding-left: 20px;
  }
  .product_space{
    width: 150px;
    float: left;
    padding-top: 20px;
    padding-left: 20px;
  }
  .product_time{
    width: 150px;
    float: left;
    padding-top: 20px;
    padding-left: 20px;
  }
  .product_price_current {
    width: fit-content;
    font-size: 14px;
    font-weight: bold;
  }

  .product_number {
    width: 90px;
    float: left;
    padding-top: 20px;
  }

  .product_number_content {
    width: fit-content;
    height: 24px;
    background: #eee;
  }


  .product_number_button button {
    width: 24px;
    height: 24px;
    outline: none;
    background: transparent;
    border: 1px solid transparent;
  }

  .product_number_button button:hover {
    background: transparent;
    border: 1px solid orange;
  }

  .product_number_input input {
    width: 30px;
    height: 24px;
    text-align: center;
    box-sizing: border-box;
  }

  .product_number_input input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }

  .product_number_input input::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  .product_amount {
    width: 100px;
    float: left;
    padding-top: 20px;
  }

  .product_total_price {
    width: 240px;
    font-size: 16px;
    color: red;
    font-weight: bold;
    word-wrap: break-word;
    text-align: center;
    text-align: center;
  }

  .product_operation {
    width: 220px;
    text-align: center;
    float: left;
    padding-top: 20px;
    line-height: 15px;
    color: #3c3c3c;
  }

  .product_operation button {
    width: fit-content;
    border: none;
    background: transparent;
    outline: none;
    font-size: 12px;
  }

  .product_operation button:hover {
    color: red;
    text-decoration: underline;
  }
</style>
