import { render, staticRenderFns } from "./gysjcjg.vue?vue&type=template&id=a0736c34&scoped=true"
import script from "./gysjcjg.vue?vue&type=script&lang=js"
export * from "./gysjcjg.vue?vue&type=script&lang=js"
import style0 from "./gysjcjg.vue?vue&type=style&index=0&id=a0736c34&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a0736c34",
  null
  
)

export default component.exports