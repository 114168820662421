<template>
  <div class="loading">
    <div class="box">
      <img width="48" src="../assets/img/loading.gif" alt="">
    </div>
  </div>
</template>

<script>
  export default {
    name: "loading"
  }
</script>

<style scoped>
.loading{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #bbb;
  z-index: 100000;
  display: flex;
  justify-content: space-between;
}
  .box{
    width: 100px;
    height: 100px;
    border: none;
    color:white;
    text-align: center;
    margin-top: 200px;
  }
</style>
