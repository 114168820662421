<template>
    <div class="box">
      <div style="height: 100px;"><img width="280" src="./assets/img/loginLogo.png" alt="食安链家" /></div>
      <p style="color: white">注册</p>
      <el-form :model="info" :rules="rules" style="margin-top: 20px;" ref="ruleForm">
        <el-form-item prop="tell">
          <el-input style="width: 320px;" @input="info.tell = tell" v-model.trim="tell" type="text" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item prop="password">
          <el-input style="width: 320px;" @input="onPasswordChange" v-model.trim="password" type="password" placeholder="密码"></el-input>
          <div v-show="password.length>=8 && password.length<=16" id="tips">
            <span :class="isOne?'s1':'colorInit'"></span>
            <span :class="isTwo?'s2':'colorInit'"></span>
            <span :class="isThree?'s3':'colorInit'"></span>
            <span :class="isFour?'s4':'colorInit'"></span>
            <span class="left5">{{ psdstrength }}</span>
          </div>
        </el-form-item>
        <el-form-item prop="confirmPassword">
          <el-input style="width: 320px;" @input="info.confirmPassword = confirmPassword" v-model.trim="confirmPassword" type="password" placeholder="确认密码"></el-input>
        </el-form-item>
        <el-form-item prop="code">
          <el-input style="width: 180px;" @input="info.code = code" v-model.trim="code" type="text" placeholder="输入验证码" ></el-input>
          <el-button @click="getCode" style="width:135px;height: 40px;margin-left: 5px;" :disabled="show?false:true">{{ show?'获取验证码':count + 's后重新获取' }}</el-button>
        </el-form-item>
      </el-form>
      <div style="margin-top: 20px;display: flex;justify-content: space-between" class="check">
        <div style="display: flex;">
          <el-button type="primary" :disabled="bool?false:true" style="height: 40px;width: 200px;" @click="logReg">注册</el-button>
        </div>
        <span style="color: white;cursor: pointer;" @click="goLogin">使用已有账户登录</span>
      </div>
      <div style="height: 50px;display: flex;align-items: center;">
        <input v-model="bool" type="checkbox"><span style="font-size: 12px;margin-left: 5px;">
        <span style="color: white">我已阅读并同意</span>
        <a style="color:#3496ed;" href="#">《食安链家用户服务协议》</a>
        <a style="color: #3496ed;">《食安链家隐私声明》</a>
        </span>
      </div>
      <div style="width: 320px;text-align: center;
      height: 40px;line-height: 40px;font-size: 14px;
      color: white;cursor: pointer;">
        <span>帮助</span>丨<span>隐私</span>丨<span>条款</span>
      </div>
    </div>
</template>

<script>
  import { JSEncrypt } from 'jsencrypt'
  export default {
    name: "login",
    data(){
      return{
        tell:"",//手机号
        password:"",//密码
        confirmPassword:"",//确认密码
        code:"",//验证码
        show:true,
        timer:null,
        count:"",
        bool:false,
        info:{
          tell:"",
          password:"",
          confirmPassword:"",
          code:"",
        },
        rules:{
          tell:[
            { required: true, message: "请输入手机号", trigger: "blur" },
            { pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/, message: "手机号格式不正确", trigger: "change" }
          ],
          password:[
            { required: true, message: "请输入密码", trigger: "blur" },
            { min: 8, max: 16, message: "密码长度不正确", trigger: "change" }
          ],
          confirmPassword:[
            { required: true, message: "请输入确认密码", trigger: "blur" },
            { validator: (rule,value,callback)=>{
              if(value!=this.password){
                callback(new Error("两次密码不一致"))
              }
              callback()
              }, trigger: "change" }
          ],
          code:[
            { required: true, message: "请输入验证码", trigger: "blur" },
          ],
        },
        isOne:false,
        isTwo:false,
        isThree:false,
        isFour:false,
        psdstrength:"",
      }
    },
    watch:{

    },
    methods:{
      //验证码倒计时
      setTim(){
        this.show = false
        const TIME_COUNT = 60
        if(!this.timer){
          this.count = TIME_COUNT
          this.show = false
          this.timer = setInterval(()=>{
            if(this.count>1&&this.count<=TIME_COUNT){
              this.count--
            }else{
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          },1000)
        }
      },
      //获取验证码
      getCode(){
        if(this.tell == ""){
          this.$message({
            message: '请输入手机号',
            type: 'warning'
          });
        }else{
          let patt = new RegExp("^1[34578][0-9]{9}$", 'i');
          if(patt.test(this.tell)){
            this.http.phoneCode('?phone='+this.tell).then(res=>{
              if(res.code == "1"){
                this.$message({message: res.msg, type: 'success'});
                this.setTim()
              }else{
                this.$message({message: res.info, type: 'warning'});
              }
            })
          }else{
            this.$message({message: '请输入正确的手机号', type: 'warning'});
          }
        }
      },
      //使用已有账户登录
      goLogin(){
        this.tell = ""//手机号
        this.password = ""//密码
        this.confirmPassword = ""//确认密码
        this.code = ""//验证码
        this.show = true
        clearInterval(this.timer)
        this.timer = null
        this.count = ""
        this.$emit("reg",false)
      },
      //注册
      logReg(){
        this.$refs['ruleForm'].validate((valid)=>{
          if(!valid){return false}
          //调用注册接口
          let data = {
            mobile: this.tell,//手机号
            pwd: this.password,//密码
            verificationCode: this.code//验证码
          }
          //将密码进行加密
          const encrypt = new JSEncrypt()
          encrypt.setPublicKey(
            'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCTREknOkrusbeH7kBe3mSw4AwVT438IWmX/jKmcvYxaAWRrBJiMl7gk37L78HBG/ZstLLcdKBYYdj/5cvVWDQfv+uxbv/piZhOmQej98jWIXEA8aFEk724nFRJ7nfcEhHSWfzbTfgZw0KDO1mWdjWHnHIx/MtD0HIFFIyzg3aO7wIDAQAB'
          )
          data.pwd = encrypt.encrypt(data.pwd)
          this.http.register(data).then(res=>{
            if(res.code == "1"){
              this.goLogin()
            }
          })
        })
      },
      onPasswordChange(val){
        this.info.password = val
        //获取用户输入的密码,然后判断其强度 返回0 或者 1 2 3 4
        let index = this.checkPassword(val);
        if(index==0){this.isOne = false;this.isTwo = false;this.isThree = false;this.isFour = false;this.psdstrength = ""
        }else if(index==1){this.isOne = true;this.isTwo = false;this.isThree = false;this.isFour = false;this.psdstrength = "弱"
        }else if(index==2){this.isTwo = true;this.isThree = false;this.isFour = false;this.isOne = false;this.psdstrength = "中"
        }else if(index==3){this.isThree = true;this.isTwo = false;this.isFour = false;this.isOne = false;this.psdstrength = "强"
        }else if(index==4){this.isFour = true;this.isOne = false;this.isTwo = false;this.isThree = false;this.psdstrength = "非常安全"
        }
      },
      checkPassword (value){
        //  0:不显示强度校验  1：弱  2:中  3:强  4:非常安全
        let modes = 0;
        if(value.length < 8){//最初级别
          return modes;
        }
        if(/\d/.test(value)){//如果用户输入的密码 包含了数字
          modes++;
        }
        if(/[a-z]/.test(value)){//如果用户输入的密码 包含了小写的a到z
          modes++;
        }
        if(/[A-Z]/.test(value)){//如果用户输入的密码 包含了大写的A到Z
          modes++;
        }
        if(/\W/.test(value)){//如果是特殊符号(非数字、字母、下划线)
          modes++;
        }
        switch(modes) {
          case 1 :return 1;break;
          case 2 :return 2;break;
          case 3 :return 3;break;
          case 4 :return 4;break;
        }
      },
    }
  }
</script>

<style scoped>
  .box{width: 320px;height: 600px;position: relative;border: none;}
  .box>div:nth-of-type(1)>img{margin: 0 auto;display: block;}
  .check{width: 320px;height: 50px;display: flex;justify-content: space-between;align-items: center;font-size: 13px;}
  #tips{font-size: 12px; width: 320px;height: 40px;backgroundr: red;position: absolute;top: 30px;left: 10px;display: flex;align-items: center}
  #tips span:not(:last-child){
    float: left;
    width: 30px;
    height: 1px;
    border: 1px solid transparent;
    font-size: 15px;
    margin-left: 10px;
  }
  .left5{
    margin-left: 5px;
  }
  .colorInit{
    background: #d2d4d5;
  }
  #tips .s1{background: #ffcf8b;}
  #tips .s2{background: #ff9a2e;}
  #tips .s3{background: #7be188;}
  #tips .s4{background: #00b42a;}
</style>





















