import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
// import utils from './utils'

import Header from "./components/Header";
Vue.component("Header",Header)

//引入和注册bootstrap-vue组件库
import BootstrapVue from "bootstrap-vue"
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue);

//全局引入线上地址
import configApi from "../public/config";
Vue.prototype.baseUrl = configApi

//引入element-ui组件库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import api from "./utils/api"
Vue.prototype.http = api

Vue.prototype.imageUrl = "https://ecanchao.com/f"

import './assets/css/index.css'

Vue.config.productionTip = false

Vue.prototype.axios=axios;


new Vue({
  router,
  store,
  render: h => h(App),
  data: {
    // 空的实例放到根组件下，所有的子组件都能调用
    Bus: new Vue()
  }
}).$mount('#app')
