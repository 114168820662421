<template>
  <div>
    <div class="head-btn" @click="$router.push({path:'/shop'})">返回云市场</div>
    <div class="login">
      <div style="width: 400px;"></div>
      <div style="width: 420px;height: 300px;color: white">
        <img width="450" src="./assets/img/loginText.png" alt="食安链家" />
        <div style="margin-top: 20px;">
          <p style="font-weight: bold;font-size: 25px;line-height: 40px;">致力于打造中国最具价值的农业产业链服务平台</p>
        </div>
      </div>
      <div v-show="userObj.register == 0" class="box">
        <div style="height: 100px;"><img width="280" src="./assets/img/loginLogo.png" alt="食安链家" /></div>
        <el-tabs style="display: flex;" v-model="activeName" @tab-click="handleClick">
          <el-tab-pane name="0">
            <span slot="label" style="color: white;">主账户登录</span>
          </el-tab-pane>
          <el-tab-pane name="1">
            <span slot="label" style="color: white;">子账户登录</span>
          </el-tab-pane>
        </el-tabs>
        <el-form :model="info" :rules="rules" ref="ruleForm">
          <el-form-item v-show="activeName == 1" prop="zhuUserPhone">
            <el-input class="inputInfo" v-model.trim="zhuUserPhone" @input="info.zhuUserPhone = zhuUserPhone" type="text" placeholder="主账户注册手机号"></el-input>
          </el-form-item>
          <el-form-item prop="user">
            <el-input class="inputInfo" v-model.trim="user" @input="info.user = user" type="text" placeholder="账户"></el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input class="inputInfo" v-model.trim="password" @input="info.password = password" show-password type="password" placeholder="密码"></el-input>
          </el-form-item>
        </el-form>
        <div style="margin-top: 15px;">
          <Verify ref="verify" @success="codeOk"></Verify>
        </div>
        <div class="check">
          <div style="display: flex;">
            <span @click="forGetPsd" style="color: white;cursor: pointer;">忘记密码?</span>
          </div>
          <span style="color: white;cursor: pointer;" @click="zhuce">注册账户</span>
        </div>
        <el-button type="primary" style="width: 100%;" @click="login">登录</el-button>
        <div style="width: 320px;text-align: center;
      height: 40px;line-height: 40px;font-size: 14px;
      color: white;cursor: pointer;">其他登录方式</div>
        <div style="width: 320px;text-align: center;
      height: 40px;line-height: 40px;font-size: 14px;
      color: white;cursor: pointer;">
          <span>帮助</span>丨<span>隐私</span>丨<span>条款</span>
        </div>
      </div>
        <Reg v-show="userObj.register == 1" @reg="reg"></Reg>
<!--      忘记密码-->
      <div v-show="userObj.register == 2" class="forGet">
        <div style="display: flex;justify-content: space-between;align-items: center;padding: 20px 50px;">
          <el-button type="text" @click="fanhuiLogin">返回</el-button>
          <div style="color: white">找回密码</div>
          <span></span>
        </div>
        <el-form style="width: 320px;margin: 0 auto;" :model="retInfo" :rules="retRules" ref="retForm">
          <el-form-item v-show="forGetShow" prop="userPhone">
            <el-input style="width: 320px;" @input="retInfo.userPhone = userPhone" v-model="userPhone" placeholder="主账号手机号" prefix-icon="el-icon-user"></el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input style="width: 320px;" @input="retInfo.phone = phone" v-model="phone" placeholder="手机号" prefix-icon="el-icon-phone"></el-input>
          </el-form-item>
          <el-form-item prop="oldPassword">
            <el-input style="width: 320px;" @input="onPasswordChange" v-model="oldPassword" placeholder="密码" type="password" show-password prefix-icon="el-icon-lock"></el-input>
            <div v-show="oldPassword.length>=8 && oldPassword.length<=16" id="tips">
              <span :class="isOne?'s1':'colorInit'"></span>
              <span :class="isTwo?'s2':'colorInit'"></span>
              <span :class="isThree?'s3':'colorInit'"></span>
              <span :class="isFour?'s4':'colorInit'"></span>
              <span class="left5">{{ psdstrength }}</span>
            </div>
          </el-form-item>
          <el-form-item prop="newPassword">
            <el-input style="width: 320px;" @input="retInfo.newPassword = newPassword" v-model="newPassword" placeholder="确认密码" type="password" show-password prefix-icon="el-icon-lock"></el-input>
          </el-form-item>
          <el-form-item prop="verCode">
            <el-input style="width: 180px;" @input="retInfo.verCode = verCode" v-model="verCode" placeholder="请输入验证码"></el-input>
            <el-button @click="getCode" style="width:135px;height: 40px;margin-left: 5px;" :disabled="show?false:true" type="primary">{{ show?'获取验证码':count + 's后重新获取' }}</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" style="width: 320px;" @click="forGetEdit" black>确认修改</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
  import Reg from "./register"
  import Verify from "./verify"
  import { JSEncrypt } from 'jsencrypt'
  import Cookies from 'js-cookie'
  export default{
    name:"",
    data(){
      return{
        userObj:{
          passFlag:false,//密码框内容明文密文的显示
          wordShow:true,//密码框明文密文icon的显示隐藏
          register:0,//0登录  1注册  2忘记密码
        },
        user:"",//账户
        password:"",//密码
        info:{
          zhuUserPhone:"",
          user:"",
          password:"",
        },
        rules:{
          zhuUserPhone:[
            { required: false, message:"请输入主账户手机号", trigger: "blur" },
            { pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/, message: "手机号格式不正确", trigger: "change" }
          ],
          user:[
            { required: true, message:"请输入手机号", trigger: "blur" },
            { pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/, message: "手机号格式不正确", trigger: "change" }
          ],
          password:[
            { required: true, message:"请输入密码", trigger: "blur" },
            { validator: (rule,value,callback)=>{
              if(value.length<8){
                callback(new Error("密码长度不能小于8位"))
              }else if(value.length>16){
                callback(new Error("密码长度不能大于16位"))
              }else{
                callback()
              }
              }, trigger: "change" }
          ],
        },
        zhuUserPhone:"",
        codeData:{},
        fullscreenLoading: true,
        loadingShow:true,
        activeName:0,
        forGetShow:false,
        userPhone:"",
        phone:"",
        oldPassword:"",
        newPassword:"",
        verCode:"",
        show:true,
        timer:null,
        count:"",
        retInfo:{
          userPhone:"",
          phone:"",
          oldPassword:"",
          newPassword:"",
          verCode:"",
        },
        retRules:{
          userPhone:[
            { required: false, message:"请输入主账户手机号", trigger: "blur" },
            { pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/, message: "手机号格式不正确", trigger: "change" }
          ],
          phone:[
            { required: true, message:"请输入子账户手机号", trigger: "blur" },
            { pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/, message: "手机号格式不正确", trigger: "change" }
          ],
          oldPassword:[
            { required: true, message:"请输入密码", trigger: "blur" },
            { validator: (rule,value,callback)=>{
                if(value.length<8){
                  callback(new Error("密码长度不能小于8位"))
                }else if(value.length>16){
                  callback(new Error("密码长度不能大于16位"))
                }else{
                  callback()
                }
              }, trigger: "change" }
          ],
          newPassword:[
            { required: true, message:"请输入确认密码", trigger: "blur" },
            { validator: (rule,value,callback)=>{
              if(value!=this.oldPassword){
                callback(new Error("两次密码不一致"))
              }
              callback()
              }, trigger: "change" }
          ],
          verCode:[
            { required: true, message:"请输入验证码", trigger: "blur" },
          ],
        },
        isOne:false,
        isTwo:false,
        isThree:false,
        isFour:false,
        psdstrength:"",
      }
    },
    components:{
      Reg,
      Verify
    },
    watch:{
      //监听密码框里面是否有内容   如果有就显示明文密文icon   如果没有就隐藏明文密文icon
      password(val){
        if(val == ""){
          this.userObj.wordShow = true
          this.userObj.passFlag = false
        }else{
          this.userObj.wordShow = false
        }
      }
    },
    mounted() {
      if(this.$route.params.str){
        this.userObj.register = this.$route.params.str
      }
      setTimeout(()=>{
        this.fullscreenLoading = false
        this.loadingShow = false
      },3000)
      this.user = ""
      this.password = ""
      this.codeData = {}
      this.$refs.verify.refresh()//重置图片验证码
    },
    methods:{
      //验证码倒计时
      setTim(){
        this.show = false
        const TIME_COUNT = 60
        if(!this.timer){
          this.count = TIME_COUNT
          this.show = false
          this.timer = setInterval(()=>{
            if(this.count>1&&this.count<=TIME_COUNT){
              this.count--
            }else{
              this.show = true
              clearInterval(this.timer)
              this.timer = null
            }
          },1000)
        }
      },
      getCode(){
        if(this.phone == ""){
          this.$message({message: '请输入手机号', type: 'warning'});
        }else{
          let patt = new RegExp("^1[34578][0-9]{9}$", 'i');
          if(patt.test(this.phone)){
            this.http.phoneCode('?phone='+this.phone).then(res=>{
              if(res.code == "1"){
                this.$message({message: res.msg, type: 'success'});
                this.setTim()
              }else{
                this.$message({message: res.info, type: 'warning'});
              }
            })
          }else{
            this.$message({message: '请输入正确的手机号', type: 'warning'});
          }
        }
      },
      //图片验证码校验成功
      codeOk(data){
        this.codeData = data
      },
      //登录模块切换至注册模块
      zhuce(){
        this.$refs.verify.refresh()
        this.userObj.register = 1
      },
      //注册模块切换至登录模块
      reg(bool){
        this.userObj.register = 0
        this.$refs.verify.refresh()
      },
      handleClick(val){
        if(val == "0"){
          // this.$refs.verify.refresh()
          this.user = ""
          this.password = ""
          this.rules.zhuUserPhone.forEach((item,index)=>{
            item.required = false
          })
        }else{
          // this.$refs.verify.refresh()
          this.zhuUserPhone = ""
          this.user = ""
          this.password = ""
          this.rules.zhuUserPhone.forEach((item,index)=>{
            item.required = true
          })
        }
      },
      //登录
      login(){
        this.$refs['ruleForm'].validate((valid)=>{
          if(!valid){return false}
          if(this.activeName == "0"){
            let data = {
              userName:this.user,//主账户账号
              mixPd:this.password,//主账户密码
              _c:this.codeData.verify,//图形验证码
            }
            //将密码进行加密
            const encrypt = new JSEncrypt()
            encrypt.setPublicKey(
              'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCTREknOkrusbeH7kBe3mSw4AwVT438IWmX/jKmcvYxaAWRrBJiMl7gk37L78HBG/ZstLLcdKBYYdj/5cvVWDQfv+uxbv/piZhOmQej98jWIXEA8aFEk724nFRJ7nfcEhHSWfzbTfgZw0KDO1mWdjWHnHIx/MtD0HIFFIyzg3aO7wIDAQAB'
            )
            data.mixPd = encrypt.encrypt(data.mixPd)
            this.http.login(data).then((res)=>{
              if(res.code == "1") {
                //将token存储cookie
                let token = res.data
                let date = new Date();
                date.setDate(date.getDate() + 1);
                document.cookie = 'token' + '=' + token + ';expires=' + date;
                Cookies.set('token', token, { expires: 1, path: 'https://cloud.yunan315.com/buyer/#' });
                let fullePath = localStorage.getItem("fullPath")
                if(fullePath){
                  this.$router.push({
                    path:fullePath
                  })
                  localStorage.removeItem("fullPath")
                }else{
                  this.$router.push({
                    path:"/shop"
                  })
                }
                location.reload()
              }else {
                this.$refs.verify.refresh()//重置图片验证码
              }
            })
          }else{
            let data = {
              masterUserName:this.zhuUserPhone,//主账户手机号
              userName:this.user,//子账户账号
              mixPd:this.password,//子账户密码
              _c:this.codeData.verify,//图形验证码
            }
            //将密码进行加密
            const encrypt = new JSEncrypt()
            encrypt.setPublicKey(
              'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCTREknOkrusbeH7kBe3mSw4AwVT438IWmX/jKmcvYxaAWRrBJiMl7gk37L78HBG/ZstLLcdKBYYdj/5cvVWDQfv+uxbv/piZhOmQej98jWIXEA8aFEk724nFRJ7nfcEhHSWfzbTfgZw0KDO1mWdjWHnHIx/MtD0HIFFIyzg3aO7wIDAQAB'
            )
            data.mixPd = encrypt.encrypt(data.mixPd)
            this.http.subLogin(data).then((res)=>{
              if(res.code == "1") {
                //将token存储cookie
                let token = res.data
                let date = new Date();
                date.setDate(date.getDate() + 1);
                document.cookie = 'token' + '=' + token + ';expires=' + date;
                Cookies.set('token', token, { expires: 1, path: this.baseUrl.buyerApiUrl });
                this.$router.push({
                  path:"/shop"
                })
                location.reload()
              }else {
                this.$refs.verify.refresh()//重置图片验证码
              }
            })
          }
        })
      },
      forGetPsd(){
        if(this.activeName == 0){
          this.userObj.register = 2
          this.forGetShow = false
          this.retRules.userPhone.forEach((item,index)=>{
            item.required  = false
          })
        }else{
          this.userObj.register = 2
          this.forGetShow = true
          this.retRules.userPhone.forEach((item,index)=>{
            item.required = true
          })
        }
      },
      fanhuiLogin(){
        this.userObj.register = 0
        this.userPhone = ""
        this.phone = ""
        this.oldPassword = ""
        this.newPassword = ""
        this.verCode = ""

      },
      forGetEdit(){
        this.$refs['retForm'].validate((valid)=>{
          if(!valid){return false}
          if(this.forGetShow){
            //  子账户
            if(this.userPhone == ""){
              this.$message({message:"请输入主账户注册手机号",type:"error"})
            }else{
              let data = {
                masterMobile:this.userPhone,
                mobile:this.phone,
                pwd:this.newPassword,
                verificationCode:this.verCode
              }
              //将密码进行加密
              const encrypt = new JSEncrypt()
              encrypt.setPublicKey(
                'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCTREknOkrusbeH7kBe3mSw4AwVT438IWmX/jKmcvYxaAWRrBJiMl7gk37L78HBG/ZstLLcdKBYYdj/5cvVWDQfv+uxbv/piZhOmQej98jWIXEA8aFEk724nFRJ7nfcEhHSWfzbTfgZw0KDO1mWdjWHnHIx/MtD0HIFFIyzg3aO7wIDAQAB'
              )
              data.pwd = encrypt.encrypt(data.pwd)
              this.http.subForGetEdit(data).then(res=>{
                if(res.code == "1"){
                  this.fanhuiLogin()
                }
              })
            }
          }else{
            //  主账户
            let data = {
              mobile:this.phone,
              pwd:this.newPassword,
              verificationCode:this.verCode
            }
            //将密码进行加密
            const encrypt = new JSEncrypt()
            encrypt.setPublicKey(
              'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCTREknOkrusbeH7kBe3mSw4AwVT438IWmX/jKmcvYxaAWRrBJiMl7gk37L78HBG/ZstLLcdKBYYdj/5cvVWDQfv+uxbv/piZhOmQej98jWIXEA8aFEk724nFRJ7nfcEhHSWfzbTfgZw0KDO1mWdjWHnHIx/MtD0HIFFIyzg3aO7wIDAQAB'
            )
            data.pwd = encrypt.encrypt(data.pwd)
            this.http.userForGetEdit(data).then(res=>{
              if(res.code == "1"){
                this.fanhuiLogin()
              }
            })
          }
        })
      },
      onPasswordChange(val){
        this.retInfo.oldPassword = val
        //获取用户输入的密码,然后判断其强度 返回0 或者 1 2 3 4
        let index = this.checkPassword(val);
        if(index==0){this.isOne = false;this.isTwo = false;this.isThree = false;this.isFour = false;this.psdstrength = ""
        }else if(index==1){this.isOne = true;this.isTwo = false;this.isThree = false;this.isFour = false;this.psdstrength = "弱"
        }else if(index==2){this.isTwo = true;this.isThree = false;this.isFour = false;this.isOne = false;this.psdstrength = "中"
        }else if(index==3){this.isThree = true;this.isTwo = false;this.isFour = false;this.isOne = false;this.psdstrength = "强"
        }else if(index==4){this.isFour = true;this.isOne = false;this.isTwo = false;this.isThree = false;this.psdstrength = "非常安全"
        }
      },
      checkPassword (value){
        //  0:不显示强度校验  1：弱  2:中  3:强  4:非常安全
        let modes = 0;
        if(value.length < 8){//最初级别
          return modes;
        }
        if(/\d/.test(value)){//如果用户输入的密码 包含了数字
          modes++;
        }
        if(/[a-z]/.test(value)){//如果用户输入的密码 包含了小写的a到z
          modes++;
        }
        if(/[A-Z]/.test(value)){//如果用户输入的密码 包含了大写的A到Z
          modes++;
        }
        if(/\W/.test(value)){//如果是特殊符号(非数字、字母、下划线)
          modes++;
        }
        switch(modes) {
          case 1 :return 1;break;
          case 2 :return 2;break;
          case 3 :return 3;break;
          case 4 :return 4;break;
        }
      },
    }
  }
</script>
<style scoped>
  .login{
    width:100vw;
    height:100vh;
    position:fixed;
    background-size:100% 100%;
    background-image: url("./assets/img/loginImg.png");
    display: flex;
    align-items: center;
  }
  .box{width: 320px !important;height: 600px;position: relative;border: none !important;}
  .box>div:nth-of-type(1)>img{margin: 0 auto;display: block;}
  .box>p:nth-of-type(1){width: 110px;height: 30px;text-align: center;border-bottom: 2px solid rgb(24,144,255);margin-left: 20px;}
  .box>input{font-size: 14px;color:#333333;width: 320px;height: 40px;border-radius: 10px;margin-top: 20px;outline: none;border: 1px solid #bbb;padding-left: 40px;}
  .sp1{position: absolute;top: 150px;left: 10px;}
  .sp2{position: absolute;top: 210px;left: 10px;}
  .sp3{position: absolute;top: 270px;left: 10px;}
  .sp4{position: absolute;top: 270px;right: 5px}
  .sp5{position: absolute;top: 270px;right: 5px}
  .wd3{position: absolute;top: 210px;right: 5px;}
  .check{width: 320px;height: 50px;display: flex;justify-content: space-between;align-items: center;font-size: 13px;}
  .forGet{
    width: 400px;height: 400px;margin: 200px auto auto auto;position: relative;
    text-align: center;
  }
  #tips{font-size: 12px; width: 320px;height: 40px;backgroundr: red;position: absolute;top: 30px;left: 135px;display: flex;align-items: center;}
  #tips span:not(:last-child){
    float: left;
    width: 30px;
    height: 1px;
    border: 1px solid transparent;
    font-size: 15px;
    margin-left: 10px;
  }
  .left5{
    margin-left: 5px;
  }
  .colorInit{
    background: #d2d4d5;
  }
  #tips .s1{background: #ffcf8b;}
  #tips .s2{background: #ff9a2e;}
  #tips .s3{background: #7be188;}
  #tips .s4{background: #00b42a;}
  .inputInfo /deep/ .el-input__inner{
    background-color: transparent !important;
    color: white !important;
    border: 1px solid rgba(255,255,255,0.6) !important;
  }
  .head-btn{
    width: 100px;
    height: 30px;
    border: 1px solid #bbbbbb;
    color: white;
    text-align: center;
    line-height: 30px;
    margin-right: 100px;
    font-size: 14px;
    border-radius: 20px;
    position: fixed;
    top: 30px;
    right: 0;
    z-index: 10000;
  }
  .head-btn:hover{
    background: white;
    cursor: pointer;
    border-radius: 20px;
    border: 1px solid white;
    color: black;

  }
</style>
