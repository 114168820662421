<template>
  <div>
    <Header></Header>
    <home></home>
  </div>
</template>

<script>
  import Header from "../components/Header";
  import Carousel from '../components/index/Carousel'
  import home from '../components/index/home'
  import SmallCarousel from '../components/index/SmallCarousel'

  export default {
    name: 'index',
    components: {
      Carousel,
      home,
      SmallCarousel,
      Header
    },
    mounted() {
      $('#head').show()
      $('#foot').show()
    }
  }
</script>

<style scoped>
</style>
