<template>
  <div style="width: 100%;height: 100vh;display: flex;align-items: center;justify-content: space-around">
    <div style="text-align: center">
      <el-result icon="success"></el-result>
      <div>支付成功</div>
      <div style="font-size: 14px;"><span></span>请在<span style="font-weight: bold;color: red;">{{ count }}</span><span style="color: red;">s</span>内做出操作，无操作将默认跳转云市场</div>
      <div>
        <el-button type="text" size="medium" @click="$router.push({path:'/shop'})">前往云市场</el-button>
        <el-button type="text" @click="goMaifangOrder" size="medium">订单管理</el-button>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "success",
    data(){
      return {
        count:0,
        timer:null,
      }
    },
    mounted() {
      this.count = 10;
      this.timer = setInterval(
        () => {
          if(this.count === 1) {
            this.$router.push({path:'/shop'})
            clearInterval(this.timer)
          }else{
            this.count --
          }
        },
        1000
      )
    },
    methods:{
      goMaifangOrder(){
        location.href = this.baseUrl.buyerApiUrl + '/overview/order'
      },
    },
  }
</script>

<style scoped>
  .el-result{
    padding: 0 !important;
  }
</style>
