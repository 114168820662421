<template>
  <div id="scrollPage">
    <Header></Header>
    <div class="top-height"></div>
    <div class="banner" :class="type">
      <div class="mask">
        <div class="ya315-container">
          <template v-if="type=='sxmd'">
            <div class="h1">生鲜门店解决方案</div>
            <div class="h2">为百万便利店开启增长：千店千面业态组合，打通线上线下，助力数字化转型。</div>
          </template>
          <template v-if="type=='zhnp'">
            <div class="h1">智慧农批解决方案</div>
            <div class="h2">依托区域级农批市场，打造区域级供应链，让城市供应链更安全、更透明、更高效。</div>
          </template>
          <template v-if="type=='zhnm'">
            <div class="h1">智慧农贸解决方案</div>
            <div class="h2">致力于为全国农贸市场提供数字化综合运营服务，方案以贯穿全流程业务的大数据平台 为核心，打造价格公示、可溯源、联成网的城区智慧农贸市场。</div>
          </template>
          <template v-if="type=='scjg'">
            <div class="h1">生产加工追溯解决方案</div>
            <div class="h2">采用“商品条码”作为关键字，实现对批次和单品的追溯；满足质检、工商等部门对食品在生产、流通过程的监管需求；符合全球重要食品安全标准和规范的要求；</div>
          </template>
          <template v-if="type=='xyncp'">
            <div class="h1">县域农产品经济体解决方案</div>
            <div class="h2">通过智慧农批、农贸平台，掌握区域农产品采集、流通及来往贸易</div>
          </template>
          <template v-if="type=='szzf'">
            <div class="h1">数字支付解决方案</div>
            <div class="h2">为百万便利店开启增长：千店千面业态组合，打通线上线下，助力数字化转型。</div>
          </template>
          <template v-if="type=='qyzs'">
            <div class="h1">企业追溯解决方案</div>
            <div class="h2">采用“商品条码”作为关键字，实现对批次和单品的追溯；满足质检、工商等部门对食品在生产、流通过程的监管需求；符合全球重要食品安全标准和规范的要求；</div>
          </template>
          <template v-if="type=='kc'">
            <div class="h1">快餐解决方案</div>
            <div class="h2">针对连锁行业如：奶茶店、小吃店、等打造一站式餐饮营销、管理系统；</div>
          </template>
          <template v-if="type=='zcls'">
            <div class="h1">中餐解决方案</div>
            <div class="h2">通过销售、预订、运营、成本管控等方面的智能化管理，有效提升顾客满意度；</div>
          </template>
          <template v-if="type=='cyxls'">
            <div class="h1">餐饮新零售解决方案</div>
            <div class="h2">适应新零售的发展特征，轻实施、大力度宣传及运营</div>
          </template>
          <template v-if="type=='hgyt'">
            <div class="h1">火锅业态解决方案</div>
            <div class="h2">自助点餐，桌边结账，加强门店及加盟商管理</div>
          </template>
          <template v-if="type=='msgc'">
            <div class="h1">美食广场解决方案</div>
            <div class="h2">优化美食广场整体服务流程，提高运营效率，获取更多粘性顾客</div>
          </template>
          <template v-if="type=='dfxc'">
            <div class="h1">地方小吃解决方案</div>
            <div class="h2">产品超轻化、移动化、组件化、一体化，助力地方小吃行业发展</div>
          </template>
          <template v-if="type=='xxjg'">
            <div class="h1">学校、机关食堂解决方案</div>
            <div class="h2">软硬件结合，通过食堂管理系统实现运营、决策，有效提升顾客体验和企业管理水平</div>
          </template>
          <template v-if="type=='zfzs'">
            <div class="h1">政府追溯解决方案</div>
            <div class="h2">共同协作搭建追溯体系示范区，企业标准化工作、政府标准组织运作</div>
          </template>
        </div>
      </div>
    </div>
    <div :class="isFixed?'fd':''" class="nav">
      <div class="ya315-container">
        <div @click="anchor(0)" :class="anchorNum==0?'cur':''">方案简介</div>
        <div @click="anchor(1)" :class="anchorNum==1?'cur':''">相关功能</div>
        <div @click="anchor(2)" :class="anchorNum==2?'cur':''">成功案例</div>
      </div>
    </div>
    <div>
      <div id="page1" class="common-title">方案简介</div>
      <template v-if="type=='kc'">
        <div class="common-desc ya315-container">根据快餐行业形势，提供后台管理，从点餐到配送提供完整的解决方案，从线上引流，吸引新客到店，从会员留存到粉丝沉淀，解决传统连锁餐饮行业营销方式单 一等问题，多种营销功能插件，多种配送方式，让连锁管理运营者更智能！</div>
      </template>
      <template v-if="type=='xxjg'">
        <div class="common-desc ya315-container">智能食堂通过在学校、政府机关、企业内部经营，遇到结算拥堵，采购管理，就餐体验差，食品安全监管不到位情况给以的解决方案。</div>
      </template>
      <template v-if="type=='dfxc'">
        <div class="common-desc ya315-container">地方小吃存在流量大，排队多，收银台经常排队结算，经营流水手工结算，费时费力，数据不准确，且第三方业务开通流程繁，耗时长，高峰期排队严重!</div>
      </template>
      <template v-if="type=='msgc'">
        <div class="common-desc ya315-container">美食广场作为新兴的餐饮方式，为此针对美食广场的经营困难做出了适合的解决方案，解决多档口管理困难，会员营销不当，办卡消费模式体验差，商家财务抽成分账复杂。</div>
      </template>
      <template v-if="type=='hgyt'">
        <div class="common-desc ya315-container">火锅业态出现引流困难，成本管控难，原材料上涨，毛利率低，门店及加盟店管理不规范等难点给予的解决方案!</div>
      </template>
      <template v-if="type=='zcls'">
        <div class="common-desc ya315-container">中餐宴会管理复杂纠纷混乱，过程复杂，部门协调频繁，易纠纷，销售渠道统计困难，提供线上预约，预定菜品，移动点餐收银，添加后厨管理，减少缺菜，误菜 的几率，提高信息完整传递。</div>
      </template>
      <template v-if="type=='cyxls'">
        <div class="common-desc ya315-container">餐饮新零售解决方案针对零售端收银低效，营销策略匮乏，运营成本高一系列问题的解决，及连锁管控困难，无法全方位看到店面的运营情况。</div>
      </template>
      <template v-if="type=='sxmd'">
        <div class="common-desc ya315-container">以降本增效为目标，为门店提供数字化收银、数字化资金，以及数字化会员系统服务，实现数字化经营优效能、数字化营销促增长、数字化门店提坪效。</div>
        <div class="info-img">
          <img src="../../assets/img/img-solution-sxmd.png" width="978" height="390" alt="">
        </div>
      </template>
      <template v-if="type=='zhnp'">
        <div class="common-desc ya315-container">智慧农贸解决方案致力于为全国农贸市场提供数字化综合运营服务，方案以贯穿全流程业务的大数据平台为核心，一应俱全的硬件设施为载体，切实解决传统农贸市场“市场管理机制粗放、政府食品安全监管滞后、商户运营低效落后、社会资源对接脱节、市民信息追踪不透明等痛点，打造价格公示、可溯源、联成网的城区智慧农贸市场。</div>
      </template>
      <template v-if="type=='zhnm'">
        <div class="common-desc ya315-container">智慧农贸解决方案致力于为全国农贸市场提供数字化综合运营服务，方案以贯穿全流程业务的大数据平台为核心，一应俱全的硬件设施为载体，切实解决传统农贸市场“市场管理机制粗放、政府食品安全监管滞后、商户运营低效落后、社会资源对接脱节、市民信息追踪不透明等痛点，打造价格公示、可溯源、联成网的城区智慧农贸市场。</div>
      </template>
      <template v-if="type=='qyzs'">
        <div class="common-desc ya315-container">帮助企业建立从源头到成品销售的追溯链条，实现从原料采购、加工过程、销售信息、检验报告等主要信息的有效追溯记录，在生产最终环节为甲方提供单品序列号生成和下载功能。</div>
        <div class="flex-box tp1 ya315-container">
          <div>
            <img src="../../assets/img/qyzs/1.png" width="48" height="48" alt="">
            <div class="title">生产加工追溯系统</div>
            <div class="desc">适用于食品农产品生产加工经营企业、饲料/疫苗/添加剂生产制造企业、种养殖企业</div>
          </div>
          <div>
            <img src="../../assets/img/qyzs/2.png" width="48" height="48" alt="">
            <div class="title">食品流通追溯系统</div>
            <div class="desc">适用于食品农产品销售企业、物流企业、农批农贸、农产品配送中心及个人散户</div>
          </div>
          <div>
            <img src="../../assets/img/qyzs/3.png" width="48" height="48" alt="">
            <div class="title">餐饮食堂追溯系统</div>
            <div class="desc">适用于食品农产品销售企业、物流企业、农批农贸、农产品配送中心及个人散户</div>
          </div>
          <div>
            <img src="../../assets/img/qyzs/4.png" width="48" height="48" alt="">
            <div class="title">商超追溯系统</div>
            <div class="desc">适用于生鲜连锁超市、品牌加盟连锁超市</div>
          </div>
        </div>
      </template>
      <template v-if="type=='scjg'">
        <div class="common-desc ya315-container">帮助生产企业持续完善产品追溯体系，满足国家追溯制度的强制要求，实现来源可查、去向可追。 以产品追溯码为媒介，创新营销方法，扩大品牌商会员体系，实现品牌推广和精准营销。 有效控制产品安全风险，一旦发生产品质量问题，支持及时召回。</div>
        <div class="common-title-md">产品赋码方案 全流程质量保证</div>
        <div class="common-desc-sm">生产线在线赋码系统，追溯的快速落地实施</div>
        <div class="common-desc-sm">追溯全产品生命周期，做产品质量的推动器</div>
        <div class="flex-box tp1 ya315-container">
          <div>
            <img src="../../assets/img/img-scjg-1.png" width="48" height="48" alt="">
            <div class="title">标准/非标设备</div>
            <div class="desc">一体式设计，搭配各种产线</div>
          </div>
          <div>
            <img src="../../assets/img/img-scjg-2.png" width="48" height="48" alt="">
            <div class="title">人机交互智能</div>
            <div class="desc">大屏显示，操作简单，信息明确</div>
          </div>
          <div>
            <img src="../../assets/img/img-scjg-3.png" width="48" height="48" alt="">
            <div class="title">软件界面可选</div>
            <div class="desc">定制开发的软件系统，更加的智能化</div>
          </div>
          <div>
            <img src="../../assets/img/img-scjg-4.png" width="48" height="48" alt="">
            <div class="title">控制系统稳定</div>
            <div class="desc">交流响应速度精准快速</div>
          </div>
          <div>
            <img src="../../assets/img/img-scjg-5.png" width="48" height="48" alt="">
            <div class="title">视觉检测系统</div>
            <div class="desc">扫描识别的同时上传至云端同步数据库</div>
          </div>
        </div>
        <div class="common-title-md">赋码类别</div>
        <div class="common-desc-sm">根据不同条形码可追溯程度不同</div>
        <div class="flex-box tp2 ya315-container">
          <div>
            <img src="../../assets/img/img-scjg-6.png" width="60" height="60" alt="">
            <div class="title">一品一码</div>
            <div class="desc">一品一码”，即赋予每批次产品一个专属“身份证”，产品有了“身份证”后，便可轻松实现追溯源头、识别真伪、追踪去向、内部控制、公众查询等功能</div>
          </div>
          <div>
            <img src="../../assets/img/img-scjg-7.png" width="60" height="60" alt="">
            <div class="title">一物一码</div>
            <div class="desc">利用云平台将消费者、企业、流通三者连接起来，从而能提供全面的O2O营销、防伪追溯、仓储物流,查询到每一件物品信息</div>
          </div>
        </div>
      </template>
      <template v-if="type=='xyncp'">
        <div class="common-desc ya315-container">依托于省级食品安全溯源系统建立起来的多层级架构的农产品流通体系，不但能够有效的为中小商业体提供分拣配货服务，同时又能够准确捕捉农产品交易大数据，通过设立专业的仓储物流园区把控农产品进入城市的流量入口，推动园区的食品检测工作落地，同时出现城市重大灾情启动应急保障体系，正直的成为人民餐桌的守护神！</div>
      </template>
      <template v-if="type=='szzf'">
        <div class="common-desc ya315-container"> 为百万便利店开启增长：千店千面业态组合，打通线上线下，助力数字化转型。</div>
      </template>
      <template v-if="type=='zfzs'">
        <div class="common-desc ya315-container">协助建立以技术标准集、实施标准集、评价标准集为核心的产品追溯标准体系，指导产品追溯平台建设、支持产品追溯信息互联互通和共享，为后期快速复制和推广奠定坚实基础。</div>
      </template>

      <div id="page2" class="common-title">相关功能</div>
      <div v-if="type=='sxmd'" class="function-info ya315-container">
        <div>
          <div class="name">进存销管理</div>
          <div class="desc">实现供应商商户入驻，平台和外采商品供应管理，分销商城配置和管理，品牌物流管理和品牌门店管理等。</div>
        </div>
        <div>
          <div class="name">在线商城管理</div>
          <div class="desc">实现生鲜门店的商户入驻，平台和外采商品供应管理、终端门店收银APP和联营商户的管理等。</div>
        </div>
        <div>
          <div class="name">联营商户管理</div>
          <div class="desc">实现品牌商商户入驻，平台和外采商品供应管理，分销商城配置和管理，品牌物流管理和品牌门店管理等。</div>
        </div>
        <div>
          <div class="name">称重商品管理</div>
          <div class="desc">为品牌商默认支撑品牌自营物流（即散户配送人员），管理车辆和司机后，司机可登录配送APP在线配送。</div>
        </div>
        <div>
          <div class="name">在销货架管理</div>
          <div class="desc">为每个入驻的品牌商开通品牌PC分销商城，供品牌商隶属门店到商城进行商品采购交易。</div>
        </div>
        <div>
          <div class="name">门店运营数据</div>
          <div class="desc">实现生鲜门店的商户入驻，平台和外采商品供应管理、终端门店收银APP和联营商户的管理等。</div>
        </div>
      </div>
      <div v-if="type=='kc'" class="function-info ya315-container">
        <div>
          <div class="name">一站多端</div>
          <div class="desc">一站多端，全平台覆盖；含微信小程序、支付宝小程序、百度小程序</div>
        </div>
        <div>
          <div class="name">单店、连锁店一键管理</div>
          <div class="desc">支持单店、连锁店统一后台管理，让餐饮运营更加省时、省力、省心</div>
        </div>
        <div>
          <div class="name">支持自定义页面</div>
          <div class="desc">可在后台管理系统编辑呈现的小程序页面，广告位管理方便快捷</div>
        </div>
        <div>
          <div class="name">快速配送</div>
          <div class="desc">可在小程序内看到配送信息及配送人员的信息</div>
        </div>
        <div>
          <div class="name">统一商品库</div>
          <div class="desc">平台添加的商品，所有门店可共享</div>
        </div>
        <div>
          <div class="name">消息快捷通知</div>
          <div class="desc">语音播报，短信提醒及横版消息和小票打印功能</div>
        </div>
      </div>
      <div v-if="type=='zcls'" class="function-info ya315-container">
        <div>
          <div class="name">线上预约</div>
          <div class="desc">提升订单的效率，减少客户流失，节省企业人力物力，提高 营业效率</div>
        </div>
        <div>
          <div class="name">智能化管理</div>
          <div class="desc">解决商机丢失、竞争损失、信息疏漏问题</div>
        </div>
        <div>
          <div class="name">移动点餐收银</div>
          <div class="desc">减少收银台压力，提高顾客满意度</div>
        </div>
        <div>
          <div class="name">前后厨联动</div>
          <div class="desc">厨显APP减低漏菜，误菜的几率，提供前厅和后厨之间的信息完整传递</div>
        </div>
        <div>
          <div class="name">多维度统计分析</div>
          <div class="desc">多维度实时查询数据，经营决策不落后，菜品结构分析，及时调整，客流量分析，发现发展趋势</div>
        </div>
        <div>
          <div class="name">VIP会员体系</div>
          <div class="desc">完整的VIP会员体系能推出营销活动，并且能留存粉丝，提高客户的粘度</div>
        </div>
      </div>
      <div v-if="type=='cyxls'" class="function-info ya315-container">
        <div>
          <div class="name">标品管理</div>
          <div class="desc">扫描标品商品条形码实现商品快速录入、查找，结合电子价钱，线上线下价格实时同步，一键切换店内促销价格</div>
        </div>
        <div>
          <div class="name">定制化设计</div>
          <div class="desc">零售业态专属模板，强化新零售行业特性</div>
        </div>
        <div>
          <div class="name">扫码下单</div>
          <div class="desc">店内扫码加购物车、小程序扫码购，「码」上加单更高效，结账更快，排队更少</div>
        </div>
        <div>
          <div class="name">高效成本管控</div>
          <div class="desc">线上线下统一库存管理，支持报损、查询报损，完善的订货系统</div>
        </div>
        <div>
          <div class="name">营业数据追踪</div>
          <div class="desc">即时消息通知，经营情况分析跟踪等功能，管理者无需亲自巡店，也能随时随地了解门店经营状况。</div>
        </div>
        <div>
          <div class="name">VIP会员体系</div>
          <div class="desc">完整的VIP会员体系能推出营销活动，并且能留存粉丝，提高客户的粘度</div>
        </div>
      </div>
      <div v-if="type=='hgyt'" class="function-info ya315-container">
        <div>
          <div class="name">套餐推荐</div>
          <div class="desc">支持火锅专用点餐模板，提高点餐效率及翻台率</div>
        </div>
        <div>
          <div class="name">积分商城</div>
          <div class="desc">获取-管理-复购，形成积分体系闭环模式，可根据积分兑换电子券、半成品礼盒及品牌周边礼品</div>
        </div>
        <div>
          <div class="name">菜品结构分析</div>
          <div class="desc">菜品结构分析优化：分析系统菜品结构，找到高利润菜品制作成本卡，提高效率，降低工作量</div>
        </div>
        <div>
          <div class="name">连锁管理</div>
          <div class="desc">实时远程在线巡店，原材料采购监控，连锁加盟订货配送管理</div>
        </div>
        <div>
          <div class="name">运营成本优化</div>
          <div class="desc">提供一套完整的体系，可以和企业一起设计流程、职责和梳理成本管控体系。</div>
        </div>
        <div>
          <div class="name">VIP会员体系</div>
          <div class="desc">完整的VIP会员体系能推出营销活动，并且能留存粉丝，提高客户的粘度</div>
        </div>
      </div>
      <div v-if="type=='msgc'" class="function-info ya315-container">
        <div>
          <div class="name">多档口运营</div>
          <div class="desc">内置强大报表工具，与餐饮管理系统对接，留存各档口营业数据，为财务管理、经营决策提供数据支撑</div>
        </div>
        <div>
          <div class="name">接收外卖订单</div>
          <div class="desc">支持在餐饮后台和各档口查看外卖点餐数量及收入，做到接单明确、数据清晰，确保美食广场运营秩序良好、管理便利</div>
        </div>
        <div>
          <div class="name">智能电子秤</div>
          <div class="desc">一台智能电子秤=触摸屏+电子秤+打印机，大大降低硬件采购成本，减少店员的操作步骤，提高运营效率</div>
        </div>
        <div>
          <div class="name">自助点餐</div>
          <div class="desc">扫描餐桌二维码查看菜品，，档口制作完成送餐给食客，大大降低排队压力，也消除了传统售卡方式带来的不便。</div>
        </div>
        <div>
          <div class="name">手持终端</div>
          <div class="desc">收银台和档口两用集打印、点单、结算于一体，适合餐车、小档口等可随时查询营业报表，掌握当前营业情况</div>
        </div>
        <div>
          <div class="name">会员留存</div>
          <div class="desc">实现会员消费数据全面抓取，形成消费画像，用数据支撑提升营业额的销售决策、差异化会员营销。</div>
        </div>
      </div>
      <div v-if="type=='dfxc'" class="function-info ya315-container">
        <div>
          <div class="name">轻量化实施</div>
          <div class="desc">极速开店，一分钟注册，无需培训，简单易上手，一体机、智能POS根据门店面积情况需要任意组合</div>
        </div>
        <div>
          <div class="name">线上下单</div>
          <div class="desc">食客自助线上点餐，到店扫码取餐，厨师凭票制作出餐，既解决了用户高峰期排长队的痛点，同时又节省的收银人员</div>
        </div>
        <div>
          <div class="name">自助点餐</div>
          <div class="desc">顾客自助点餐、结算，降低人工服务成本，缩短顾客排队时间，高峰期也不怕，提升食客就餐体验</div>
        </div>
        <div>
          <div class="name">账目管控</div>
          <div class="desc">自动交班，减少不必要的工作流，权限管理，避免私单，规避吃钱</div>
        </div>
        <div>
          <div class="name">聚合外卖</div>
          <div class="desc">统一菜品上下架管理，统一自动接单／厨打，统一对账和分析报表</div>
        </div>
        <div>
          <div class="name">一体化会员管理</div>
          <div class="desc">精准获取会员消费信息，通过多种卡型会员消费数据沉淀，进一步分析会员消费行为。</div>
        </div>
      </div>
      <div v-if="type=='xxjg'" class="function-info ya315-container">
        <div>
          <div class="name">线上支付、评价</div>
          <div class="desc">整合多种支付模式，顾客消费后线上评价，管理者第一时间接收，使食堂做到有效改进</div>
        </div>
        <div>
          <div class="name">提前订餐</div>
          <div class="desc">线上订餐，家长可为孩子订餐并掌握其用餐情况和营养</div>
        </div>
        <div>
          <div class="name">菜品结构分析</div>
          <div class="desc">卡务管理，一键式快速处理卡务及补贴业务，营养菜谱，根据周期内用餐情况给出膳食建议</div>
        </div>
        <div>
          <div class="name">管理数据化</div>
          <div class="desc">通过智能菜谱计划合理备餐，大幅减少浪费问题，电子营业数据实现手机电脑双端在线查看</div>
        </div>
        <div>
          <div class="name">运营成本优化</div>
          <div class="desc">提供一套完整的体系，可以和企业一起设计流程、职责和梳理成本管控体系。</div>
        </div>
        <div>
          <div class="name">食品安全监管</div>
          <div class="desc">从供应商资质证照的管理、菜品新鲜度到销售环节的层层把关，可以从原材料到成品全流程每个节点进行合理的监管。</div>
        </div>
      </div>
      <template v-if="type=='zfzs'">
        <div class="common-desc ya315-container">协助建立以技术标准集、实施标准集、评价标准集为核心的产品追溯标准体系，指导产品追溯平台建设、支持产品追溯信息互联互通和共享，为后期快速复制和推广奠定坚实基础。</div>
        <div class="tp5 ya315-container">
          <div>
            <img src="../../assets/solution/img-xyncp-1.png" height="48" width="48" alt="">
            <div class="title">食品追溯平台</div>
            <div class="desc">建立食品农产品追溯监管平台，可从、原料、采购、制作、加工、包装、订单流向、销售，全数码身份监控。</div>
          </div>
          <div>
            <img src="../../assets/solution/img-xyncp-2.png" height="48" width="48" alt="">
            <div class="title">公共查询系统</div>
            <div class="desc">消费者可以通过微信小程序、网站查询产品追溯信息，追溯信息 内容包括食品信息、企业信息、检验等信息。</div>
          </div>
          <div>
            <img src="../../assets/solution/img-xyncp-3.png" height="48" width="48" alt="">
            <div class="title">监管子系统</div>
            <div class="desc"><br>通过对数据源分析，形成可视化监管系统，供政府查看食品农产品流通及价格信息。</div>
          </div>
        </div>
        <div class="common-title" style="font-size: 26px;color:#52565B;font-weight: normal;">城市应急保障体系</div>
        <div class="ya315-container" style="margin-top:54px;">
          <div style="display: flex;justify-content: space-between;">
            <div style="height:200px;width:586px;border:1px solid #DDE0E7;">
              <div style="padding:40px 0 24px 40px;color:#121A25;font-size: 20px;">建立价格监控体系</div>
              <div style="padding:0 40px 40px;color:#52565B;font-size: 16px;">建立溯源平台商户统一后台价格监控体系，对于各个入驻主体内价格实时监控，并具有后台最高权限统一调价功能</div>
            </div>
            <div style="height:200px;width:586px;border:1px solid #DDE0E7;">
              <div style="padding:40px 0 24px 40px;color:#121A25;font-size: 20px;">雷达搜索功能</div>
              <div style="padding:0 40px 40px;color:#52565B;font-size: 16px;">具有雷达搜索功能，方便周边消费者移动端搜索签约商户，并在线了解市场商户经营品类和享受订单跑腿配送到家服务</div>
            </div>
          </div>
          <div style="display: flex;justify-content: space-between;margin-top:30px;">
            <div style="height:200px;width:586px;border:1px solid #DDE0E7;">
              <div style="padding:40px 0 24px 40px;color:#121A25;font-size: 20px;">提供配送服务</div>
              <div style="padding:0 40px 40px;color:#52565B;font-size: 16px;">提供为平台溯源商户设立的农产品集采集供配送服务，将平台商户采购行为，统一物流配送服务及检测，避免市场商户采购渠道混乱带来的低效率及食品安全风险</div>
            </div>
            <div style="height:200px;width:586px;border:1px solid #DDE0E7;">
              <div style="padding:40px 0 24px 40px;color:#121A25;font-size: 20px;">数字化结算体系</div>
              <div style="padding:0 40px 40px;color:#52565B;font-size: 16px;">将全产业链打通保障产品的及时供应的过程中让种养殖基地和生产厂家的种养殖及生产过程中的投入物也纳入到监管体系</div>
            </div>
          </div>
        </div>
        <div class="ya315-container" style="margin-top:96px;">
          <img src="../../assets/img/img-zfzs.png" alt="">
        </div>
      </template>
      <template v-if="type=='scjg'">
        <div class="common-desc ya315-container">为生产企业提供生产赋码管理功能，快速高效化，及产品召回管理。</div>
        <div class="common-title-md">生产赋码管理系统功能介绍</div>
        <div class="common-desc-sm">给产品赋码，实现一物一码追溯</div>
        <div class="tp3">
          <div>
            <img src="../../assets/img/img-scjg-8.png" height="36" width="36" alt="">
            <div class="title">自动赋码</div>
            <div class="desc">通过输送装置或喷头的运动控制系统实现产品的自动定位赋码，配合视觉检测系统，可以保证赋码合格率，提升商品良率。</div>
          </div>
          <div>
            <img src="../../assets/img/img-scjg-9.png" height="36" width="36" alt="">
            <div class="title">视觉检测</div>
            <div class="desc">CCD视觉检测系统，可以实现内容有无识别、OCR字符内容识别、动态二维码识别等功能，扫描识别的同时上传至云端同步数据库。</div>
          </div>
          <div>
            <img src="../../assets/img/img-scjg-10.png" height="36" width="36" alt="">
            <div class="title">不良剔除</div>
            <div class="desc"><br>在产线自动化线上输送的产品，在识别不通过的情况下会传输信号给剔除机构，并记录、关联至系统。</div>
          </div>
          <div>
            <img src="../../assets/img/img-scjg-11.png" height="36" width="36" alt="">
            <div class="title">自动收放料</div>
            <div class="desc">为了符合自动化工厂的发展趋势，潜利可根据客户产品实际情况，进行非标自动化收放料的研发、设计，提升工作效率，降低人力成本。</div>
          </div>
          <div>
            <img src="../../assets/img/img-scjg-12.png" height="36" width="36" alt="">
            <div class="title">自动预警</div>
            <div class="desc">标配数据通讯串口，可以外接警报设备或者和其他智能设备联机，实现异常自动报警，告知产线操作人员，降低异常损失，提升效率。</div>
          </div>
        </div>
        <div class="common-title-md">产品召回管理</div>
        <div class="common-desc-sm">产品出现问题，可快速进行召回</div>
        <div class="tp4 flex-box">
          <img src="../../assets/img/img-scjg-13.png" width="380" height="266" alt="">
          <div>
            <div><img src="../../assets/img/img-scjg-14.png" width="6" height="6" alt=""> 当产品发生问题时，为召回提供精确的数据</div>
            <div><img src="../../assets/img/img-scjg-14.png" width="6" height="6" alt=""> 在生产过程中，避免问题重现性</div>
            <div><img src="../../assets/img/img-scjg-14.png" width="6" height="6" alt=""> 发生问题，为责任追究提供准确的依据</div>
            <div><img src="../../assets/img/img-scjg-14.png" width="6" height="6" alt=""> 通过采集的信息，做多维度数据分析报表为生产计划打下数据基础</div>
            <div><img src="../../assets/img/img-scjg-14.png" width="6" height="6" alt=""> 通用性原则，适合各行业的生产制造企业管理</div>
            <div><img src="../../assets/img/img-scjg-14.png" width="6" height="6" alt=""> 可扩展性：留有开发接口，可信息交互</div>
            <div><img src="../../assets/img/img-scjg-14.png" width="6" height="6" alt=""> 可移植性：充分考虑软件的模块的独立性，方便以后可以按要求进行模块间无缝链接</div>
          </div>
        </div>
      </template>
      <template v-if="type=='xyncp'">
        <div class="common-title-md">农产品追溯体系</div>
        <div class="tp5 ya315-container">
          <div>
            <img src="../../assets/solution/img-xyncp-1.png" width="48" height="48" alt="">
            <div class="title">县级追溯平台</div>
            <div class="desc">建立县级的食品农产品追溯监管平台，可从、原料、采购、制作、加工、包装、订单流向、销售，全数码身份监控</div>
          </div>
          <div>
            <img src="../../assets/solution/img-xyncp-2.png" width="48" height="48" alt="">
            <div class="title">公共查询系统</div>
            <div class="desc">消费者可以通过微信小程序、网站查询产品追溯信息，追溯信息 内容包括食品信息、企业信息、检验等信息。</div>
          </div>
          <div>
            <img src="../../assets/solution/img-xyncp-3.png" width="48" height="48" alt="">
            <div class="title">监管子系统</div>
            <div class="desc">通过对数据源分析，形成可视化监管系统，供政府查看食品农产品流通及价格信息</div>
          </div>
        </div>
        <div class="common-title-md">城市应急保障体系</div>
        <div class="tp6 ya315-container">
          <div>
            <div class="title">建立价格监控体系</div>
            <div class="desc">建立溯源平台商户统一后台价格监控体系，对于各个入驻主体内价格实时监控，并具有后台最高权限统一调价功能</div>
          </div>
          <div>
            <div class="title">雷达搜索功能</div>
            <div class="desc">具有雷达搜索功能，方便周边消费者移动端搜索签约商户，并在线了解市场商户经营品类和享受订单跑腿配送到家服务</div>
          </div>
          <div>
            <div class="title">提供配送服务</div>
            <div class="desc">提供为平台溯源商户设立的农产品集采集供配送服务，将平台商户采购行为，统一物流配送服务及检测，避免市场商户采购渠道混乱带来的低效率及食品安全风险</div>
          </div>
          <div>
            <div class="title">数字化结算体系</div>
            <div class="desc">将全产业链打通保障产品的及时供应的过程中让种养殖基地和生产厂家的种养殖及生产过程中的投入物也纳入到监管体系</div>
          </div>
        </div>
        <img style="display: block;margin-top:85px;" class="ya315-container" src="../../assets/solution/img-xyncp-4.png" height="477" alt="">
      </template>
      <template v-if="type=='qyzs'">
        <div class="common-desc ya315-container">帮助品牌商持续完善产品追溯体系，满足国家追溯制度的强制要求，实现来源可查、去向可追。 以产品追溯码为媒介，创新营销方法，扩大品牌商会员体系，实现 品牌推广和精准营销。 有效控制产品安全风险，一旦发生产品质量问题，支持及时召回。</div>
        <div class="flex-box tp2 ya315-container">
          <div>
            <img src="../../assets/img/img-scjg-6.png" width="60" height="60" alt="">
            <div class="title">一品一码</div>
            <div class="desc">一品一码”，即赋予每批次产品一个专属“身份证”，产品有了“身份证”后，便可轻松实现追溯源头、识别真伪、追踪去向、内部控制、公众查询等功能</div>
          </div>
          <div>
            <img src="../../assets/img/img-scjg-7.png" width="60" height="60" alt="">
            <div class="title">一物一码</div>
            <div class="desc">利用云平台将消费者、企业、流通三者连接起来，从而能提供全面的O2O营销、防伪追溯、仓储物流,查询到每一件物品信息</div>
          </div>
        </div>
      </template>
      <template v-if="type=='szzf'">
        <div class="common-title-md">多种支付方式</div>
        <div class="tp5 ya315-container">
          <div>
            <img class="img1" src="../../assets/solution/img-szzf-1.png" width="48" height="48" alt="">
            <div class="title title1">聚合支付</div>
            <div class="pro-advantage">
              <div><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:16px;" alt="">智能POS</div>
              <div><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:16px;" alt="">微信、支付宝、云闪付等主流方式</div>
              <div><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:16px;" alt="">扫码枪支付，配合管理系统</div>
            </div>
          </div>
          <div>
            <img class="img1" src="../../assets/solution/img-szzf-2.png" width="48" height="48" alt="">
            <div class="title title1">线上收银台</div>
            <div class="pro-advantage">
              <div><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:16px;" alt="">多种支付渠道</div>
              <div><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:16px;" alt="">完善订单支付体系</div>
              <div><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:16px;" alt="">严格过程风险监控</div>
            </div>
          </div>
          <div>
            <img class="img1" src="../../assets/solution/img-szzf-3.png" width="48" height="48" alt="">
            <div class="title title1">银企直连</div>
            <div class="pro-advantage">
              <div><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:16px;" alt="">轻松管理资金</div>
              <div><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:16px;" alt="">快速报表统计</div>
              <div><img src="../../assets/img/icon-ok.png" width="12" height="8" style="margin-right:16px;" alt="">严格过程风险监控</div>
            </div>
          </div>
        </div>
      </template>
      <template v-if="type=='zhnp'">
        <div class="page2-common ya315-container">
          <div class="page2-common-nav c5">
            <div :class="page2NavIndex==0?'cur':''" @click="page2NavIndex=0">总部管控集中化</div>
            <div :class="page2NavIndex==1?'cur':''" @click="page2NavIndex=1">市场运营数字化</div>
            <div :class="page2NavIndex==2?'cur':''" @click="page2NavIndex=2">商户业务精细化</div>
            <div :class="page2NavIndex==3?'cur':''" @click="page2NavIndex=3">市场数据可视化</div>
            <div :class="page2NavIndex==4?'cur':''" @click="page2NavIndex=4">生态服务平台全链化</div>
          </div>
          <div class="page2-common-content">
            <img v-if="page2NavIndex==0" src="../../assets/img/zhnp-1.png" width="440" height="300" alt="">
            <img v-if="page2NavIndex==1" src="../../assets/img/zhnp-2.png" width="440" height="300" alt="">
            <img v-if="page2NavIndex==2" src="../../assets/img/zhnp-3.png" width="440" height="300" alt="">
            <img v-if="page2NavIndex==3" src="../../assets/img/zhnp-4.png" width="440" height="300" alt="">
            <img v-if="page2NavIndex==4" src="../../assets/img/zhnp-5.png" width="440" height="300" alt="">
            <div>
              <h2 v-if="page2NavIndex==0">多市场、跨区域集中管控</h2>
              <h2 v-if="page2NavIndex==1">市场综合营运统筹管理</h2>
              <h2 v-if="page2NavIndex==2">商户全流程业务精细管理</h2>
              <h2 v-if="page2NavIndex==3">集成市场大数据透明可视</h2>
              <h2 v-if="page2NavIndex==4">多方生态服务平台赋能</h2>
              <h3 v-if="page2NavIndex==0">集团化管理，跨区域布局</h3>
              <h3 v-if="page2NavIndex==1">市场物业、内部资金、商户档口统筹管理</h3>
              <h3 v-if="page2NavIndex==2">采配销流程高效协同，档口业务精细化管理</h3>
              <h3 v-if="page2NavIndex==3">市场运营、商品行情实时更新</h3>
              <h3 v-if="page2NavIndex==4">多方生态服务平台，产业链全维度拓展</h3>
              <template v-if="page2NavIndex==0">
                <div class="con"><span>总部管控集中化</span>支持总部跨省市集团化管理；市场多层级架构连锁化统一管理；系统后台多市场自由切换，统一视图</div>
              </template>
              <template v-if="page2NavIndex==1">
                <div class="con"><span>市场管理</span>商户、区位、商品、合同、缴费统一数字化管理</div>
                <div class="con"><span>商户管理</span>档口与商户信息关联合同，合同条款灵活设定，签订即自动生成商户档案</div>
                <div class="con"><span>缴费管理</span>费用灵活设置，账单自动下派、手机查询缴费，销售数据自动生成</div>
                <div class="con"><span>进场管理</span>车辆进场智能识别车、货信息，极速收费</div>
              </template>
              <template v-if="page2NavIndex==2">
                <div class="con"><span>采配加工</span>采配销全流程信息互通，移动化协同、精细化管理、全渠道分销，商品高效流转</div>
                <div class="con"><span>商户管理</span>档口极速开单收银、费用账期智能结算，财务精益管理；全方位、多维报表分析助决策</div>
              </template>
              <template v-if="page2NavIndex==3">
                <div class="con"><span>市场行情</span>商品品类分布、单品排行、价格行情与检测信息实时同步，透明可视</div>
                <div class="con"><span>市场运营</span>市场账户情况、市场商户概况与销售信息结果可视化</div>
              </template>
              <template v-if="page2NavIndex==0">
                <div class="con"><span>政府方面</span>政府平台无缝对接，监管信息实时报送；对接当地政务服务，业务办理便捷高效</div>
                <div class="con"><span>金融方面</span>金融机构有效介入，实现金融增值服务共享</div>
                <div class="con"><span>产业链方面</span>对接冷链物流、仓储配送、交易撮合等供应链服务，大数据集成赋能行业</div>
              </template>
              <a href="">免费试用</a>
            </div>
          </div>
        </div>
      </template>
      <template v-if="type=='zhnm'">
        <div class="page2-common ya315-container">
          <div class="page2-common-nav c6">
            <div :class="page2NavIndex==0?'cur':''" @click="page2NavIndex=0">统一管理</div>
            <div :class="page2NavIndex==1?'cur':''" @click="page2NavIndex=1">灵活设置</div>
            <div :class="page2NavIndex==2?'cur':''" @click="page2NavIndex=2">收银提现</div>
            <div :class="page2NavIndex==3?'cur':''" @click="page2NavIndex=3">支付分润</div>
            <div :class="page2NavIndex==4?'cur':''" @click="page2NavIndex=4">社会化监管协同</div>
            <div :class="page2NavIndex==5?'cur':''" @click="page2NavIndex=5">上下游资源链接</div>
          </div>
          <div class="page2-common-content">
            <img v-if="page2NavIndex==0" src="../../assets/img/zhnm/1.png" width="440" height="300" alt="">
            <img v-if="page2NavIndex==1" src="../../assets/img/zhnm/2.png" width="440" height="300" alt="">
            <img v-if="page2NavIndex==2" src="../../assets/img/zhnm/3.png" width="440" height="300" alt="">
            <img v-if="page2NavIndex==3" src="../../assets/img/zhnm/4.png" width="440" height="300" alt="">
            <img v-if="page2NavIndex==4" src="../../assets/img/zhnm/5.png" width="440" height="300" alt="">
            <img v-if="page2NavIndex==5" src="../../assets/img/zhnm/6.png" width="440" height="300" alt="">
            <div>
              <h2 v-if="page2NavIndex==0">多市场、跨区域集中管控</h2>
              <h2 v-if="page2NavIndex==1">市场综合营运统筹管理</h2>
              <h2 v-if="page2NavIndex==2">商户全流程业务精细管理</h2>
              <h2 v-if="page2NavIndex==3">集成市场大数据透明可视</h2>
              <h2 v-if="page2NavIndex==4">多方生态服务平台赋能</h2>
              <h2 v-if="page2NavIndex==5">多方生态服务平台赋能</h2>
              <h3 v-if="page2NavIndex==0">集团化管理，跨区域布局</h3>
              <h3 v-if="page2NavIndex==1">市场物业、内部资金、商户档口统筹管理</h3>
              <h3 v-if="page2NavIndex==2">采配销流程高效协同，档口业务精细化管理</h3>
              <h3 v-if="page2NavIndex==3">市场运营、商品行情实时更新</h3>
              <h3 v-if="page2NavIndex==4">多方生态服务平台，产业链全维度拓展</h3>
              <h3 v-if="page2NavIndex==5">多方生态服务平台，产业链全维度拓展</h3>
              <template v-if="page2NavIndex==0">
                <div class="con"><span>总部管控集中化</span>支持总部跨省市集团化管理；市场多层级架构连锁化统一管理；系统后台多市场自由切换，统一视图</div>
              </template>
              <template v-if="page2NavIndex==1">
                <div class="con"><span>市场管理</span>商户、区位、商品、合同、缴费统一数字化管理</div>
                <div class="con"><span>商户管理</span>档口与商户信息关联合同，合同条款灵活设定，签订即自动生成商户档案</div>
                <div class="con"><span>缴费管理</span>费用灵活设置，账单自动下派、手机查询缴费，销售数据自动生成</div>
                <div class="con"><span>进场管理</span>车辆进场智能识别车、货信息，极速收费</div>
              </template>
              <template v-if="page2NavIndex==2">
                <div class="con"><span>采配加工</span>采配销全流程信息互通，移动化协同、精细化管理、全渠道分销，商品高效流转</div>
                <div class="con"><span>商户管理</span>档口极速开单收银、费用账期智能结算，财务精益管理；全方位、多维报表分析助决策</div>
              </template>
              <template v-if="page2NavIndex==3">
                <div class="con"><span>市场行情</span>商品品类分布、单品排行、价格行情与检测信息实时同步，透明可视</div>
                <div class="con"><span>市场运营</span>市场账户情况、市场商户概况与销售信息结果可视化</div>
              </template>
              <template v-if="page2NavIndex==4">
                <div class="con"><span>政府方面</span>政府平台无缝对接，监管信息实时报送；对接当地政务服务，业务办理便捷高效</div>
                <div class="con"><span>金融方面</span>金融机构有效介入，实现金融增值服务共享</div>
                <div class="con"><span>产业链方面</span>对接冷链物流、仓储配送、交易撮合等供应链服务，大数据集成赋能行业</div>
              </template>
              <template v-if="page2NavIndex==5">
                <div class="con"><span>政府方面</span>政府平台无缝对接，监管信息实时报送；对接当地政务服务，业务办理便捷高效</div>
                <div class="con"><span>金融方面</span>金融机构有效介入，实现金融增值服务共享</div>
                <div class="con"><span>产业链方面</span>对接冷链物流、仓储配送、交易撮合等供应链服务，大数据集成赋能行业</div>
              </template>
              <a href="">免费试用</a>
            </div>
          </div>
        </div>
      </template>

      <div id="page3" class="common-title">成功案例</div>
      <div v-if="type=='zhnm'||type=='zhnp'" class="ya315-container" style="position:relative;margin-top:70px;margin-bottom:200px;color:#fff;text-align: center;">
        <img src="../../assets/img/zhnm/foot.png" width="1200" height="413" alt="">
        <h3 style="position:absolute;font-size: 30px;width:100%;top:155px;">河南省商丘市民权新农汇农批商贸城</h3>
        <p style="position:absolute;font-size: 20px;top:225px;width:100%;">河南省商丘市民权新农汇农批商贸城</p>
      </div>
      <div v-if="type=='qyzs'" class="customer-case ya315-container">
        <div class="case-left">
          <div><img src="../../assets/img/img-logo-zj.png" width="120" height="75" alt="仲记餐饮"></div>
          <div><img src="../../assets/img/img-logo-tf.png" width="181" height="37" alt="郑州同发牧业有限公司"></div>
        </div>
        <div class="case-right">
          <div class="case-right-mask">
            <div class="case-title">餐超供应链平台</div>
            <div class="case-desc">插上互联网的翅膀，搭建属于自己餐厅的“线上线下一体”的生态系统</div>
            <div class="case-btn">阅读案例</div>
          </div>
        </div>
      </div>
      <div v-if="type=='xyncp'"  class="customer-case ya315-container">
        <div class="case-left">
          <div>民权追溯管理系统</div>
        </div>
        <div class="case-right" :class="type">
          <div class="case-right-mask">
            <div class="case-title">民权县追溯管理系统</div>
            <div class="case-desc">根据追溯平台、监管子系统、公共查询系统，打造 县域农产品追溯体系和城市应急体系</div>
          </div>
        </div>
      </div>
      <div v-if="type=='sxmd'"  class="customer-case ya315-container">
        <div class="case-left">
          <div><img src="../../assets/img/img-sxmd2.png" width="225" height="51" alt="仲记餐饮"></div>
        </div>
        <div class="case-right" :class="type">
          <div class="case-right-mask">
            <div class="case-title">仲记超级鲜生</div>
            <div class="case-desc">以“数智营销”为切入点，重构品牌线下营销模式，提效 连锁零售商</div>
            <div class="case-btn">阅读案例</div>
          </div>
        </div>
      </div>
      <div v-if="type=='kc'"  class="customer-case ya315-container">
        <div class="case-left">
          <div><img src="../../assets/img/img-sxmd2.png" width="225" height="51" alt="仲记餐饮"></div>
        </div>
        <div class="case-right" :class="type">
          <div class="case-right-mask">
            <div class="case-title">仲记超级鲜生</div>
            <div class="case-desc">以“数智营销”为切入点，重构品牌线下营销模式，提效 连锁零售商</div>
            <div class="case-btn">阅读案例</div>
          </div>
        </div>
      </div>
      <div v-if="type=='cyxls'"  class="customer-case ya315-container">
        <div class="case-left">
          <div><img src="../../assets/img/img-cyxls2.png" width="120" height="75" alt="仲记餐饮"></div>
        </div>
        <div class="case-right" :class="type">
          <div class="case-right-mask">
            <div class="case-title">仲记手擀面</div>
            <div class="case-desc">插上互联网的翅膀，搭建属于自己餐厅的“线上线下一 体”的生态系统</div>
            <div class="case-btn">阅读案例</div>
          </div>
        </div>
      </div>
      <div v-if="type=='hgyt'"  class="customer-case ya315-container">
        <div class="case-left">
          <div><img src="../../assets/solution/img-hgyt3.png" width="120" height="75" alt="仲记餐饮"></div>
        </div>
        <div class="case-right" :class="type">
          <div class="case-right-mask">
            <div class="case-title">寻鲜纪</div>
            <div class="case-desc">主打新鲜生态食品，还消费者一个更健康的生活消费理念。</div>
            <div class="case-btn">阅读案例</div>
          </div>
        </div>
      </div>
      <div v-if="type=='msgc'||type=='xxjg'"  class="customer-case ya315-container">
        <div class="case-left">
          <div><img src="../../assets/solution/img-hgyt3.png" width="120" height="75" alt="仲记餐饮"></div>
        </div>
        <div class="case-right" :class="type">
          <div class="case-right-mask">
            <div class="case-title">寻鲜纪</div>
            <div class="case-desc">主打新鲜生态食品，还消费者一个更健康的生活消费理念。</div>
            <div class="case-btn">阅读案例</div>
          </div>
        </div>
      </div>
      <div v-if="type=='zcls'"  class="customer-case ya315-container">
        <div class="case-left">
          <div><img src="../../assets/img/img-sxmd2.png" width="225" height="51" alt="仲记餐饮"></div>
        </div>
        <div class="case-right" :class="type">
          <div class="case-right-mask">
            <div class="case-title">仲记豫满楼</div>
            <div class="case-desc">插上互联网的翅膀，搭建属于自己餐厅的“线上线下一体”的生态环境</div>
            <div class="case-btn">阅读案例</div>
          </div>
        </div>
      </div>
      <div v-if="type=='szzf'"  class="customer-case ya315-container">
        <div class="case-left">
          <div style="height:144px;"><img src="../../assets/img/szzf-foot1.png" width="170" height="142" alt=""></div>
        </div>
        <div class="case-right" :class="type">
          <div class="case-right-mask">
            <div class="case-title">惠农通支付 </div>
            <div class="case-desc">覆盖线上+线下全场景的真正的企业支付产品， 集银行及三方支付一体化的综合账户体系， 智能分账、统一清分对账，解放财务</div>
            <div class="case-btn">阅读案例</div>
          </div>
        </div>
      </div>
      <div v-if="type=='dfxc'"  class="customer-case ya315-container">
        <div class="case-left">
          <div><img src="../../assets/img/img-cyxls2.png" width="120" height="75" alt="仲记餐饮"></div>
        </div>
        <div class="case-right" :class="type">
          <div class="case-right-mask">
            <div class="case-title">仲记手擀面</div>
            <div class="case-desc">仲记手擀面传承传统工艺，精选优质食材，具有“手工擀制、劲道有味、入口爽滑、原汁卤香”四大特色。</div>
            <div class="case-btn">阅读案例</div>
          </div>
        </div>
      </div>
      <div v-if="type=='scjg'"  class="customer-case ya315-container">
        <div class="case-left">
          <div><img src="../../assets/img/img-logo-tf.png" width="181" height="37" alt="仲记餐饮"></div>
        </div>
        <div class="case-right" :class="type">
          <div class="case-right-mask">
            <div class="case-title">蛋鸡溯源供应链系统</div>
            <div class="case-desc">蛋鸡综合服务平台和生态圈，打造供应链平台，打造蛋鸡绿色供应链运作模式</div>
            <div class="case-btn">阅读案例</div>
          </div>
        </div>
      </div>
      <div v-if="type=='zfzs'"  class="customer-case ya315-container">
        <div class="case-left">
          <div><img src="../../assets/img/logo-sjzs.png" width="60" height="42" style="margin-right:16px;" alt="">省级追溯系统</div>
          <div>民权追溯管理系统</div>
        </div>
        <div class="case-right" :class="type">
          <div class="case-right-mask">
            <div class="case-title">民权县追溯管理系统</div>
            <div class="case-desc">根据追溯平台、监管子系统、公共查询系统，打造 县域农产品追溯体系和城市应急体系</div>
            <div class="case-btn">阅读案例</div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  export default {
    name: "index",
    data(){
      return {
        type:'',
        anchorNum:0,
        page2NavIndex:0,
        isFixed:false
      }
    },
    watch:{
      $route(to,form){
        this.type = this.$route.query.type
      }
    },
    mounted() {
      $('#head,#foot').show();
      this.type = this.$route.query.type
      if (document.addEventListener) {//firefox
        document.addEventListener('DOMMouseScroll', this.scrollFuc, false);
      }
      //滚动滑轮触发scrollFunc方法 //ie 谷歌
      window.onmousewheel = document.onmousewheel = this.scrollFuc;
    },
    methods:{
      scrollFuc(e){
        e = e || window.event;
        if (e.wheelDelta) { //第一步：先判断浏览器IE，谷歌滑轮事件
          if (document.documentElement.scrollTop > 300) { //当滑轮向上滚动时
            this.isFixed = true
          }else{
            this.isFixed = false
          }
        } else if (e.detail) { //Firefox滑轮事件
          if (document.body.scrollTop> 300) { //当滑轮向上滚动时
            this.isFixed = true
          }else{
            this.isFixed = false
          }
        }
      },
      anchor(n){
        this.anchorNum = n;
        let top = 0
        if(n==0){
          top = $('#page1').offset().top-72-60
        }else if(n==1){
          top = $('#page2').offset().top-72-60
        }else{
          top = $('#page3').offset().top-72-60
        }
        $('html').scrollTop(top)
      }
    }
  }
</script>

<style scoped>
  .top-height{height:60px;}
  .banner{width:100%;height:300px;}
  .banner.sxmd{background-image:url(../../assets/img/bg-solution-sxmd.png);background-size: 100% 100%;position: relative}
  .banner.zhnp{background-image:url(../../assets/img/bg-solution-zhnp.png);background-size: 100% 100%;position: relative}
  .banner.zhnm{background-image:url(../../assets/img/bg-solution-zhnm.png);background-size: 100% 100%;position: relative}
  .banner.scjg{background-image:url(../../assets/img/banner-scjg.png);background-size: 100% 100%;position: relative}
  .banner.xyncp{background-image:url(../../assets/img/bg-banner-xyncp.png);background-size: 100% 100%;position: relative}
  .banner.qyzs{background-image:url(../../assets/img/qyzs/banner.png);background-size: 100% 100%;position: relative}
  .banner.szzf{background-image:url(../../assets/solution/bg-solution-szzf.png);background-size: 100% 100%;position: relative}
  .banner.cyxls{background-image:url(../../assets/img/img-cyxls1.png);background-size: 100% 100%;position: relative}
  .banner.hgyt{background-image:url(../../assets/solution/img-hgyt.png);background-size: 100% 100%;position: relative}
  .banner.msgc{background-image:url(../../assets/img/img-msgc.png);background-size: 100% 100%;position: relative}
  .banner.dfxc{background-image:url(../../assets/img/img-dfxc.png);background-size: 100% 100%;position: relative}
  .banner.xxjg{background-image:url(../../assets/solution/img-xxjg.png);background-size: 100% 100%;position: relative}
  .banner.zfzs{background-image:url(../../assets/solution/zfzs-banner.png);background-size: 100% 100%;position: relative}
  .banner.kc{background-image:url(../../assets/solution/kc-banner.png);background-size: 100% 100%;position: relative}
  .banner.zcls{background-image:url(../../assets/solution/zc-banner.png);background-size: 100% 100%;position: relative}
  .mask{width:100%;height:100%;background:rgba(3, 36, 70, 0.48);position: absolute}
  .mask>div{position:relative;height:300px;}
  .banner .h1{color:#ffffff;font-size: 40px;text-align: center;width:100%;position:absolute;top:82px;}
  .banner .h2{color:#E3E3E3;font-size: 20px;text-align: center;position:absolute;top:175px;left:50%;margin-left:-410px;width:820px;}
  .nav{height:54px;border-bottom:1px solid #E8E9EB;width:100%;background:#fff;overflow: hidden;}
  .nav.fd{position:fixed;top:60px;z-index: 10;}
  .nav>div{display: flex;justify-content: center;}
  .nav>div>div{width:64px;margin:0 146px;height:54px;border-bottom:2px solid transparent;line-height: 54px;font-size: 16px;color:#52565B;text-align: center;cursor: pointer;}
  .nav>div>div.cur{color:#1890FF;border-bottom-color: #1890FF;}
  .common-title{color:#2690FD;font-size: 30px;margin-top:72px;text-align: center;font-weight: 700;}
  .common-title-md{color:#121A25;font-size: 26px;margin-top:100px;text-align: center;margin-bottom:24px;}
  .common-desc{color:#52565B;font-size: 16px;margin-top:39px;line-height: 36px;letter-spacing: 1px;}
  .common-desc-sm{margin-bottom:12px;color:#707070;font-size: 16px;text-align: center;}
  .info-img{text-align: center;margin-top:77px;}
  .function-info{display: flex;justify-content: space-between;flex-wrap:wrap;margin-top:48px;}
  .function-info>div{width:390px;height:173px;background:url(../../assets/img/bg-solution-function.png);margin-bottom:30px;position:relative;}
  .function-info .name{position:absolute;top:36px;left:30px;font-size: 20px;color:#ffffff;}
  .function-info .desc{position:absolute;top:89px;left:30px;right:40px;font-size: 12px;color:#DEECFE;}
  .customer-case{height:461px;display: flex;margin-top:65px;margin-bottom:400px;}
  .case-left{width:247px;display: flex;flex-direction:column;border:1px solid #E8E9EB;justify-content: center;text-align: center;}
  .case-left>div{height:115px;font-size: 16px;color:#121A25;text-align: center;line-height: 115px;}
  .case-right{width:953px;background:url(../../assets/img/bg-case-img1.png);}
  .case-right-mask{background:url(../../assets/img/bg-case-mask.png);width:724px;height:461px;float:right;color:#fff;position:relative;}
  .case-right.xyncp{background:url(../../assets/img/xyncp-foot.png)}
  .case-right.szzf{background:url(../../assets/img/szzf-foot2.png)}
  .case-right.sxmd{background:url(../../assets/img/img-sxmd.png)}
  .case-right.cyls{background:url(../../assets/img/bg-case-img1.png)}
  .case-right.cyxls{background:url(../../assets/img/img-cyxls.png)}
  .case-right.hgyt{background:url(../../assets/solution/img-hgyt2.png)}
  .case-right.dfxc{background:url(../../assets/img/img-dfxc2.png)}
  .case-right.zfzs{background:url(../../assets/solution/zfzs-case.png)}
  .case-title{font-size: 34px;position:absolute;top:84px;left:179px;}
  .case-desc{font-size: 20px;position:absolute;top:183px;left:179px;width:480px;}
  .case-btn{width:123px;height:39px;border:1px solid #ffffff;text-align: center;line-height: 38px;font-size: 20px;position:absolute;left:179px;bottom:94px;color:#ffffff;cursor: pointer;}
  .flex-box{justify-content: space-between;}
  .tp1>div{width:240px;height:200px;position:relative;margin-top:52px;}
  .tp1>div>img{position:absolute;top:36px;left:50%;margin-left:-24px;}
  .tp1>div>.title{position:absolute;top:100px;width:100%;text-align: center;color:#121A25;font-size: 20px;}
  .tp1>div>.desc{position:absolute;top:144px;width:180px;text-align: center;color:#707070;left:50%;margin-left:-90px;}
  .tp2>div{width:574px;height:247px;border:1px solid #DDE0E7;border-radius: 4px;position:relative;margin-top:60px;}
  .tp2>div>img{position:absolute;top:31px;left:31px;}
  .tp2>div>.title{font-size: 28px;color:#121A25;top:43px;left:114px;position:absolute;}
  .tp2>div>.desc{width:512px;left:30px;top:127px;position:absolute;font-size: 20px;color:#707070;}
  .tp3{margin-top:60px;}
  .tp3>div{width:1002px;margin:0 auto 50px;position:relative;height:52px;}
  .tp3>div>img{position:absolute;top:8px;left:0;}
  .tp3>div>.title{font-size: 20px;color:#121A25;font-weight: bold;left:72px;top:13px;position:absolute;}
  .tp3>div>.desc{width:764px;right:0;top:0;color:#52565B;font-size: 16px;vertical-align: middle;position:absolute;}
  .tp4{width:1002px;margin:76px auto 0;justify-content: space-between;}
  .tp4>img{margin-top:34px;}
  .tp4>div>div{color:#52565B;font-size: 16px;margin-bottom:29px;line-height: 21px;white-space: nowrap;position:relative;right:-62px;}
  .tp4>div>div>img{margin-right:10px;}
  .tp5{display: flex;justify-content: space-between;margin-top:50px;}
  .tp5>div{width:390px;height:286px;position:relative;border:1px solid #DDE0E7;border-radius: 4px;}
  .tp5>div>img{position:absolute;top:48px;left:30px;}
  .tp5>div>img.img1{top:44px;left:36px;}
  .tp5>div>.title{position:absolute;font-size: 26px;color:#121A25;top:57px;left:91px;}
  .tp5>div>.title.title1{color:#121A25;top:50px;left:102px;}
  .tp5>div>.desc{color:#707070;font-size: 16px;width:328px;height:113px;top:127px;left:30px;position: absolute;}
  .pro-advantage{position:absolute;top:127px;left:36px;color:#52565B;font-size: 14px;}
  .pro-advantage>div{margin-bottom:10px;}
  .tp6{margin-top:54px;display: flex;justify-content: space-between;flex-wrap:wrap;}
  .tp6>div{width:586px;height:201px;border:1px solid #DDE0E7;position:relative;margin-bottom:30px;}
  .tp6>div>.title{color:#121A25;font-size: 20px;top:44px;left:41px;position:absolute;}
  .tp6>div>.desc{color:#52565B;font-size: 16px;position:absolute;top:97px;left:41px;width:505px;}

  .page2-common{box-shadow: 0 5px 21px 0 rgb(0 0 0 / 5%);}
  .page2-common-nav{height:54px;display: flex;margin-bottom:20px;margin-top:40px;}
  .page2-common-nav>div{border-top:4px solid #333333;text-align: center;line-height: 54px;background:#f7f7f7;}
  .page2-common-nav>div.cur{border-top-color: #197bdf;background:#ffffff;color:#197bdf;}
  .c5>div{width:20%;}
  .c6>div{width:200px;}
  .page2-common-content{padding:40px 80px 0 40px;display: flex;justify-content: space-between;align-items: flex-start;color: #333;}
  .page2-common-content>div{width:544px;padding-bottom:36px;}
  .page2-common-content>img{position:relative;top:26px;}
  .page2-common-content>div h2{font-size: 20px;font-weight: 700;padding-left: 40px;margin-bottom: 15px;}
  .page2-common-content>div h3{font-size: 16px;line-height: 24px;padding-left: 40px;margin-bottom: 25px;font-weight: 400;}
  .page2-common-content>div .con{background: url(../../assets/img/bingo.png) 3px 5px no-repeat;font-size: 16px;padding-left: 40px;color: #999;line-height: 24px;margin-bottom: 15px;}
  .page2-common-content>div .con>span{display: block;color:#333333;}
  .page2-common-content>div a{width: 176px;height: 44px;background: #197bdf;font-size: 18px;color: #fff;line-height: 44px;margin-left: 40px;text-align: center;margin-top: 30px;display: block;cursor: pointer;}

</style>
