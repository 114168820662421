<template>
  <div>
    <Header></Header>
    <div class="top-height"></div>
    <div class="banner" :class="'type'+type">
      <div class="mask">
        <div class="ya315-container">
          <div v-if="type==1" class="banner-title">渠道供应伙伴</div>
          <div v-if="type==2" class="banner-title">交付服务伙伴</div>
          <div v-if="type==3" class="banner-title">产品及方案合作</div>
          <div v-if="type==1" class="banner-desc">与食安链家协同开拓市场，为客户提供全产品销售服务。</div>
          <div v-if="type==2" class="banner-desc">具备食安链家产品或服务的交付能力，为客户设计、架构、搭建、迁移和管理其在云市场上的业务和应用。</div>
          <div v-if="type==3" class="banner-desc">解决方案伙伴是基于食安链家服务，构建包括独立软件、开发平台、软件服务、软件中间件、技术方案等的技术类合作伙伴。</div>
          <a class="banner-btn" style="margin-left: 360px;" href="">立即加入</a>
        </div>
      </div>
    </div>
    <div class="common-title">合作计划权益</div>
    <div class="ya315-container">
      <img v-if="type==1" src="../../assets/img/c2-page1.png" alt="">
      <img v-if="type==2" src="../../assets/img/c3-page1.png" alt="">
      <img v-if="type==3" src="../../assets/img/c4-page1.png" alt="">
    </div>
    <div class="common-title">合作伙伴要求</div>
    <div class="ya315-container" style="margin-bottom:186px;">
      <img v-if="type==1" src="../../assets/img/c2-page2.png" alt="">
      <img v-if="type==2" src="../../assets/img/c3-page2.png" alt="">
      <img v-if="type==3" src="../../assets/img/c4-page2.png" alt="">
    </div>
    <div style="height:417px;background:#F6F8FF;padding-top:1px;">
      <div class="common-title">申请流程</div>
      <div class="page6 ya315-container">
        <img src="../../assets/img/c-page6.png" alt="">
      </div>
      <div class="cooperation-btn blue" style="margin:0 auto;">立即加入</div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "index1",
    data(){
      return {
        type:1
      }
    },
    watch:{
      $route(to,form){
        this.type = this.$route.query.type
      }
    },
  }
</script>

<style scoped>
  .top-height{height:60px;}
  .banner{width:100%;height:300px;background-image: url(../../assets/img/c2-banner1.png);background-size: 100% 100%;margin-bottom:36px;position: relative}
  .mask{background:rgba(0,0,0,.4);width:100%;height:100%;position: absolute}
  .banner-title{font-size: 40px;color:#ffffff;padding-top:50px;margin-bottom:21px;margin-left: 360px;}
  .banner-desc{font-size: 20px;color:rgba(255,255,255,.6);margin-left: 360px;}
  .banner-btn{width:180px;height:48px;line-height: 48px;text-align: center;color:#ffffff;font-size: 22px;border-radius: 4px;margin-top: 41px;display: block;background:#2690FD;}
  .common-title{color:#52565B;font-size: 30px;text-align: center;margin-top:66px;margin-bottom:56px;}
  .page6{margin-top: 55px;text-align: center;margin-bottom:86px;}
  .cooperation-btn{cursor:pointer;width:130px;height:36px;border:1px solid #707070;text-align:center;border-radius: 8px;box-sizing: border-box;line-height: 34px;}
  .blue{color:#fff;background:#2690FD;border-color:#2690FD;box-shadow: 0 0 6px rgba(148, 200, 254, .88)}
</style>
