<template>
  <div class="ya315 home" style="width:100%;">
    <div class="ya315-head">
      <div class="ya315-container">
<!--        <product class="ya315-head-product" src="../../assets/product/ya_title.png" width="138" height="30" alt="">-->
        <div class="ya315-bg"></div>
        <div class="ya315-title2">打造中国最具价值的农业产业链服务平台</div>
        <div class="ya315-title3">借助食安链家产业链系统构建供应链信息流、物流、现金流的完整闭环</div>
        <a href="https://salj.yunan315.com/shop" target="_blank" class="ya315-head-btn">立即体验</a>
        <div class="ya315-list">
          <div>
            <div>
              <div class="icon"></div>
              <div class="h1">产品信息全程溯源</div>
              <div class="h2">全链追溯 保证产品安全</div>
            </div>
          </div>

          <div>
            <div>
              <div class="icon icon2"></div>
              <div class="h1">定制化服务</div>
              <div class="h2">可为不同商户定制化</div>
            </div>
          </div>
          <div>
            <div>
              <div class="icon icon3"></div>
              <div class="h1">标准化管理</div>
              <div class="h2">供应链各环节标准化管理</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ya315-container">
      <div class="ya315-title" style="margin-top:163px;">多系统后台 为商家提供各场景智能管理</div>
      <div class="ya315-info">为商户提供稳定，高效，智能的集仓库进销存管理综合分析多环节为一体的供应链管理解决方案</div>
      <div class="ya315-page2-nav">
        <div @click="page2NavVal=1" :class="page2NavVal==1?'cur':''">
          <div class="icon"></div>
          <div class="p">农互通</div>
          <div class="line"></div>
        </div>
        <div @click="page2NavVal=2" :class="page2NavVal==2?'cur':''">
          <div class="icon"></div>
          <div class="p">商互通</div>
          <div class="line"></div>
        </div>
        <div @click="page2NavVal=3" :class="page2NavVal==3?'cur':''">
          <div class="icon"></div>
          <div class="p">云管家</div>
          <div class="line"></div>
        </div>
        <div @click="page2NavVal=4" :class="page2NavVal==4?'cur':''">
          <div class="icon"></div>
          <div class="p">云集仓</div>
          <div class="line"></div>
        </div>
        <div @click="page2NavVal=5" :class="page2NavVal==5?'cur':''">
          <div class="icon"></div>
          <div class="p">云集物</div>
          <div class="line"></div>
        </div>
      </div>
      <div>
        <div v-if="page2NavVal==1" class="ya315-page2-content nav1">
          <div>
            <div class="title">农互通</div>
            <div class="p">品牌下，企业通过基础设定、模版等功能对门店对作业流程进行集权管理实行连锁企业中央集权化管理</div>
            <div class="list">
              <!--                        <div>-->
              <!--                            <div class="icon"></div>-->
              <!--                            <div class="p">采集采供商城</div>-->
              <!--                        </div>-->
              <!--                        <div>-->
              <!--                            <div class="icon"></div>-->
              <!--                            <div class="p">供应商后台</div>-->
              <!--                        </div>-->
              <router-link :to="{path:'/product/one',query:{type:'dj'}}">
<!--                href="https://tf.yunan315.com/store"-->
                <div class="icon"></div>
                <div class="p">蛋鸡产业链平台</div>
              </router-link>
              <a href="" target="_blank">
                <div class="icon"></div>
                <div class="p">牛羊养殖平台</div>
              </a>
            </div>
          </div>
          <div class="img"></div>
        </div>
        <div v-if="page2NavVal==2" class="ya315-page2-content nav2">
          <div>
            <div class="title">商互通</div>
            <div class="p">通过功能对门店授权，对不同形态的门店按照集群进行管理，对旗下各个门店按照其经营情况进行差异化管理</div>
            <div class="list">
<!--              https://salj.yunan315.com/shop/pages/user/login/index-->
              <router-link :to="{path:'/product/one',query:{type:'spncp'}}">
                <div class="icon"></div>
                <div class="p">食品农产品大数据<br/>资源管理平台</div>
              </router-link>
<!--              href="https://salj.yunan315.com/brand"-->
              <router-link :to="{path:'/product/one',query:{type:'pps'}}">
                <div class="icon"></div>
                <div class="p">品牌商SAAS<br/>管理平台</div>
              </router-link>
              <router-link :to="{path:'/product/one',query:{type:'fxs'}}" href="" target="_blank">
                <div class="icon"></div>
                <div class="p">分销商SAAS<br/>管理平台</div>
              </router-link>
              <router-link :to="{path:'/product/one',query:{type:'gysjcjg'}}">
                <div class="icon"></div>
                <div class="p">供应商集采集供<br/>数据管理平台</div>
              </router-link>
              <router-link :to="{path:'/product/one',query:{type:'ppsht'}}">
                <div class="icon"></div>
                <div class="p">品牌商后台供应链<br/>管理平台</div>
              </router-link>
              <router-link :to="{path:'/product/one',query:{type:'fxsjcjg'}}">
                <div class="icon"></div>
                <div class="p">分销商后台供应链<br/>管理平台</div>
              </router-link>
            </div>
          </div>
          <div class="img"></div>
        </div>
        <div v-if="page2NavVal==3" class="ya315-page2-content nav3 flag">
          <div id="tabNav" class="nav-tab">
            <div id="type1" class="page" @click="page2Nav3Tab=1" :class="page2Nav3Tab==1?'cur':''"></div>
            <div id="type2" class="page" @click="page2Nav3Tab=2" :class="page2Nav3Tab==2?'cur':''"></div>
          </div>
          <div class="content-type type1" v-if="page2Nav3Tab==1">
            <div>
              <div class="title">云餐饮</div>
              <div class="p">供应商可上架各品牌的商品，可管理品牌广告、优惠活动等，供门店进行多品牌多品类采购</div>
              <div class="list">
                <router-link :to="{path:'/product/one',query:{type:'cyls'}}" >
                  <div class="icon"></div>
                  <div class="p">餐饮连锁SAAS<br/>管理平台</div>
                </router-link>
                <router-link :to="{path:'/product/one',query:{type:'cysy'}}">
                  <div class="icon"></div>
                  <div class="p">餐饮门店收银<br/>APP</div>
                </router-link>
                <router-link :to="{path:'/product/one',query:{type:'dcps'}}">
                  <div class="icon"></div>
                  <div class="p">点餐配送<br/>APP</div>
                  <div class="code">
                    <img src="../../assets/img/img-code-dcwl.png" alt="">
                  </div>
                </router-link>
                <router-link :to="{path:'/product/one',query:{type:'fwydc'}}">
                  <div class="icon"></div>
                  <div class="p">服务员点餐<br/>APP</div>
                </router-link>
                <router-link :to="{path:'/product/one',query:{type:'cxapp'}}">
                  <div class="icon"></div>
                  <div class="p">厨显APP</div>
                </router-link>
                <router-link :to="{path:'/product/one',query:{type:'smdc'}}">
                  <div class="icon"></div>
                  <div class="p">扫码点餐小程序</div>
                </router-link>
              </div>
            </div>
            <div class="img"></div>
          </div>
          <div class="content-type type2" v-if="page2Nav3Tab==2">
            <div>
              <div class="title">云生鲜</div>
              <div class="p">供应商可上架各品牌的商品，可管理品牌广告、优惠活动等，供门店进行多品牌多品类采购</div>
              <div class="list">
                <router-link :to="{path:'/product/one',query:{type:'sxls'}}">
                  <div class="icon"></div>
                  <div class="p">生鲜连锁SAAS<br/>管理平台</div>
                </router-link>
                <router-link :to="{path:'/product/one',query:{type:'sxsy'}}">
                  <div class="icon"></div>
                  <div class="p">门店收银APP</div>
                </router-link>
                <router-link :to="{path:'/product/one',query:{type:'zxsc'}}">
                  <div class="icon"></div>
                  <div class="p">门店在线商城小程序</div>
                  <div class="code"><img src="../../assets/img/img-code-sxwm.png" alt=""></div>
                </router-link>
                <router-link :to="{path:'/product/one',query:{type:'sxps'}}">
                  <div class="icon"></div>
                  <div class="p">门店配送APP</div>
                </router-link>
              </div>
            </div>
            <div class="img"></div>
          </div>
        </div>
        <div v-if="page2NavVal==4" class="ya315-page2-content nav4">
          <div>
            <div class="title">云集仓</div>
            <div class="p">满足全供应链的协同仓储管理，同时支持供应链上各种类型的仓储需求。</div>
            <div class="list">
              <router-link :to="{path:'/product/one',query:{type:'ccgl'}}">
                <div class="icon"></div>
                <div class="p">仓储SAAS管理平台</div>
              </router-link>
            </div>
          </div>
          <div class="img"></div>
        </div>
        <div v-if="page2NavVal==5" class="ya315-page2-content nav5">
          <div>
            <div class="title">云集物</div>
            <div class="p">记录每一笔库存交易，每个物品的生命周期都能够准确刻画，为商户提供精准的库存记录</div>
            <div class="list">
              <router-link :to="{path:'/product/one',query:{type:'wls'}}">
                <div class="icon"></div>
                <div class="p">物流商SAAS<br/>管理平台</div>
              </router-link>
              <router-link :to="{path:'/product/one',query:{type:'wlps'}}">
                <div class="icon"></div>
                <div class="p">物流配送APP</div>
              </router-link>
            </div>
          </div>
          <div class="img"></div>
        </div>
      </div>
    </div>
    <div class="ya315-block">
      <div class="ya315-container">
        <div class="ya315-block-img"></div>
        <div class="ya315-block-info">
          <div class="ya315-block-title">多重身份 一站登录</div>
          <div class="ya315-block-ps">支持门店后台、品牌后台、平台后台、品牌商城等各身份用户登录，方便快捷，助您一站登录</div>
        </div>
      </div>
    </div>
    <div class="ya315-block">
      <div class="ya315-container">
        <div class="ya315-block-info">
          <div class="ya315-block-title">规范流程 带您成功入驻</div>
          <div class="ya315-block-ps">入驻类型选择、信息录入、资料审核、签署协议等规范流程，带您成功入驻录</div>
        </div>
        <div class="ya315-block-img img2"></div>
      </div>
    </div>
    <div class="ya315-swiper">
      <div class="ya315-title">多功能栏目 帮你管理后台</div>
      <div class="ya315-info">便捷从操作，让一切可视、可控、可管</div>
      <div class="ya315-swiper-box">
        <div class="bg-dot"></div>
        <div class="product-block">
          <div class="o-title-list">
            <div @click="cIndex=0" :class="cIndex==0?'cur':''" class="o-title-item">
              <div class="dot-line"></div>
              <div class="o-title">防伪追溯</div>
            </div>
            <div @click="cIndex=1" :class="cIndex==1?'cur':''" class="o-title-item">
              <div class="dot-line"></div>
              <div class="o-title">供应链金融</div>
            </div>
            <div @click="cIndex=2" :class="cIndex==2?'cur':''" class="o-title-item">
              <div class="dot-line"></div>
              <div class="o-title">农产品流通</div>
            </div>
            <div @click="cIndex=3" :class="cIndex==3?'cur':''" class="o-title-item">
              <div class="dot-line"></div>
              <div class="o-title">餐饮连锁</div>
            </div>
          </div>
          <div class="o-title-list" style="left:220px;">
            <div @click="cIndex=4" :class="cIndex==4?'cur':''" class="o-title-item">
              <div class="dot-line"></div>
              <div class="o-title">生鲜超市</div>
            </div>
          </div>
          <div class="o-icon-box">
            <div @click="prev" class="to-icon to-prev"></div>
            <div @click="next" class="to-icon to-next"></div>
          </div>
          <div class="o-num">0{{cIndex+1}}/<span>05</span></div>
        </div>
        <div class="product-box">
          <b-carousel
            ref="myCarousel"
            v-model="cIndex"
            :interval="0"
            img-width="560"
            img-height="440"
          >
            <b-carousel-slide>
              <div class="swiper-slide ">
                <div class="swp-info">
                  <div class="swp-icon icon1"></div>
                  <div class="swp-p">防伪追溯</div>
                  <div class="swp-ps">为企业提供从源头加工到成品销售的商品追溯体系，打造政府追溯平台建设、支持产品追溯信息互联互通和共享</div>
                </div>
                <div class="swp1"></div>
              </div>
            </b-carousel-slide>
            <b-carousel-slide>
              <div class="swiper-slide">
                <div class="swp-info">
                  <div class="swp-icon icon2"></div>
                  <div class="swp-p">供应链金融</div>
                  <div class="swp-ps">通过运用金融科技，深入产业链和场景，有效结合金融资源，助力实体经济</div>
                </div>
                <div class="swp2"></div>
              </div>
            </b-carousel-slide>
            <b-carousel-slide>
              <div class="swiper-slide">
                <div class="swp-info">
                  <div class="swp-icon icon3"></div>
                  <div class="swp-p">农产品流通</div>
                  <div class="swp-ps">通过智慧农批、农贸平台，掌握区域农产品采集、流通及来往贸易</div>
                </div>
                <div class="swp3"></div>
              </div>
            </b-carousel-slide>
            <b-carousel-slide>
              <div class="swiper-slide">
                <div class="swp-info">
                  <div class="swp-icon icon4"></div>
                  <div class="swp-p">餐饮连锁</div>
                  <div class="swp-ps">对接多渠道系统供应商，整合线上资源及数据，打造数字生态闭环，实现业务与数据的双向指导</div>
                </div>
                <div class="swp4"></div>
              </div>
            </b-carousel-slide>
            <b-carousel-slide>
              <div class="swiper-slide">
                <div class="swp-info">
                  <div class="swp-icon icon4"></div>
                  <div class="swp-p">生鲜超市</div>
                  <div class="swp-ps">系统化运营门店云商品库，助力商家及时了解库存信息，并实现无障碍对接第三方平台</div>
                </div>
                <div class="swp4"></div>
              </div>
            </b-carousel-slide>
          </b-carousel>
        </div>
      </div>
    </div>
    <div class="ya315-banner">
      <div style="height:1px;"></div>
      <div class="ya315-title">食安链家后台</div>
      <div class="ya315-info">多平台管理，专业信赖</div>
      <div class="banner-nav" id="bannerNav">
        <div @click="bannerNav=1" :class="bannerNav==1?'cur':''">智能终端SAAS管理平台</div>
        <div @click="bannerNav=2" :class="bannerNav==2?'cur':''">品牌商SAAS管理平台</div>
        <div @click="bannerNav=3" :class="bannerNav==3?'cur':''">统一管理平台</div>
      </div>
      <div>
        <div v-if="bannerNav==1" class="banner-content t1">
          <div class="left-img"></div>
          <div class="right-content">
            <div>
              <p>为餐饮服务业商家提供软硬一体的SaaS整体解决方案，帮助商家实现从门店管理、会员营销、供应链管理的全流程数字化，点餐收银、排队外卖、会员营销、进销存管理等多功能高效管理店铺，降低运营成本，提高经营效率。帮助商家实现数字化、智能化升级。</p>
              <a href="https://salj.yunan315.com/store" target="_blank">登录平台</a>
            </div>
          </div>
        </div>
        <div v-if="bannerNav==2" class="banner-content t2">
          <div class="left-img"></div>
          <div class="right-content">
            <div>
              <p>品牌强则农业强，做好品牌建设既牵住现代农业“牛鼻子”、又攥住农民增收“钱袋子”。实现线上和线上的融合发展，开发线上供需产业链模式，重点打造一条独具特色的上游采购到下游销售的营销线路，搭配小B端品牌商城在线订购，灵活管理下游的终端门店等各项服务。</p>
              <a href="https://salj.yunan315.com/brand" target="_blank">登录平台</a>
            </div>
          </div>
        </div>
        <div v-if="bannerNav==3" class="banner-content t3">
          <div class="left-img"></div>
          <div class="right-content">
            <div>
              <p>聚焦生鲜零售行业，利用移动互联、云计算、大数据、人工智能和物联网等先进技术；聚合移动支付、全渠道营销及大数据运营等数字化全方位服务，驱动零售行业的数字化转型，为供应商、品牌商和门店终端提供集在线供应、物流监控、门店管理和资金结算等服务于一体，专注于生鲜零售行业的生态管理平台。</p>
              <a href="https://salj.yunan315.com/center" target="_blank">登录平台</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ya315-banner" style="padding-top:1px;height:652px;">
      <div class="ya315-title">相关合作伙伴</div>
      <div class="page4 ya315-container">
        <img src="../../assets/img/c-page4-1.png" alt="">
        <img src="../../assets/img/c-page4-2.png" alt="">
        <img src="../../assets/img/c-page4-3.png" alt="">
        <img src="../../assets/img/c-page4-4.png" alt="">
        <img src="../../assets/img/c-page4-5.png" alt="">
        <img src="../../assets/img/c-page4-6.png" alt="">
        <img src="../../assets/img/c-page4-7.png" alt="">
      </div>
    </div>
    <div class="ya315-banner2" style="height:469px;padding-top:1px;background:#1890FF;position:relative">
      <img src="../../assets/img/img-index-foot.png" alt="" style="position:absolute;top:0;left:50%;margin-left:-574px;">
        <div class="ya315-title" style="color:#ffffff;">食安链家合作与生态</div>
        <div class="ya315-info" style="width:600px;color:#ffffff;">我们为合作伙伴提供先进的智能技术、成熟的营销策略、共同探索创新解决方案，助理企业数字化转型，实现业务快速增长</div>
        <div class="index-foot-btn">
          <a href="">成为合作伙伴</a>
          <a href="">了解更多</a>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "home",
  data(){
    return {
      page2NavVal:1,
      page2Nav3Tab:1,
      cIndex:0,
      bannerNav:1,
    }
  },
  methods:{
    prev(){
      this.$refs.myCarousel.prev()
    },
    next(){
      this.$refs.myCarousel.next()
    }
  }
}
</script>

<style scoped>
.ya315-swiper-box{width:1200px;height:488px;position:relative;margin:0 auto;}
.ya315-head {width: 100%;height: 600px;background-image: url("../../assets/img/banner.png");background-position: center;background-size:100% 100%;}
.ya315-head > div {position: relative;height:600px;}
.ya315-bg {width: 538px;height: 424px;position: absolute;top: 100px;right: -100px;background:url(../../assets/img/banner-img.png) no-repeat;background-size:490px auto;}
.ya315-title2 {color: #333;font-size: 36px;position: absolute;left:0;top:160px;}
.ya315-title3 {width: 500px;color: #333;font-size: 20px;position: absolute;left: 0;top:274px;}
.ya315-head-btn{display:block;width:152px;height:48px;background-image: linear-gradient(to right, #399BEF , #0768DC);position:absolute;left:0;top:390px;border-radius: 24px;font-size: 20px;color:#fff!important;text-align: center;line-height: 48px;cursor: pointer;}
.ya315-head-btn:hover,.ya315-head-btn:visited{color:#fff;}
.bg-dot{background:url(../../assets/img/bg-dot.png) repeat;height:158px;}
.product-block{width:1200px;height:330px;bottom:0;left:0;background:#1890ff;}
.product-box{width:560px;height:440px;right:40px;bottom:40px;box-shadow: 0 12px 24px rgba(105,123,140,.2);position:absolute;background:#fff;}
.o-icon-box{position:absolute;right:620px;bottom:40px;}
.to-icon{width: 40px;height: 40px;background-image:url(../../assets/img/icon-next.png);background-size:100% 100%;}
.to-prev{background-image:url(../../assets/img/icon-prev.png);background-size:100% 100%;}
.o-title-list{top:198px;left:40px;color:#fff;position:absolute;width:200px;}
.o-title-item{margin-bottom: 15px;width:100%;height:30px;cursor: pointer;}
.o-title-item>div{float:left;}
.dot-line{width: 4px;height: 4px;margin-right: 16px;background: #fff;-webkit-transition: width .3s cubic-bezier(.7,.3,.1,1),height .3s cubic-bezier(.7,.3,.1,1);transition: width .3s cubic-bezier(.7,.3,.1,1),height .3s cubic-bezier(.7,.3,.1,1);margin-top:12px;}
.cur .dot-line{width:32px;height:2px;}
.o-title{color: hsla(0,0%,100%,.85);font-size: 14px;line-height: 26px;-webkit-transition: font-weight .3s cubic-bezier(.7,.3,.1,1),line-height .3s cubic-bezier(.7,.3,.1,1),font-size .3s cubic-bezier(.7,.3,.1,1);transition: font-weight .3s cubic-bezier(.7,.3,.1,1),line-height .3s cubic-bezier(.7,.3,.1,1),font-size .3s cubic-bezier(.7,.3,.1,1);}
.cur .o-title{font-weight: 600;font-size: 18px;color:#fff;}
.o-num{position:absolute;bottom:40px;left:40px;font-size: 30px;color:#fff;font-weight: 600;}
.o-num>span{font-size: 18px;opacity: .6;}
  .list{width:500px;}
.page4{display: flex;flex-wrap:wrap;margin-top:50px;width:1000px;}
  .index-foot-btn{display: flex;justify-content: center;}
  .index-foot-btn>a{display: block;height:50px;line-height: 50px;box-sizing: border-box;border-radius: 4px;font-size: 20px;text-align: center;width:178px;color:#1890FF;margin:48px 10px;background:#ffffff;}
  .index-foot-btn>a:last-child{border:1px solid #ffffff;color:#ffffff;background:rgba(255,255,255,.2);}
</style>