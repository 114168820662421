<template>
  <div>
    <Header></Header>
    <dj-page v-if="type=='dj'"></dj-page>
    <spncp-page v-if="type=='spncp'"></spncp-page>
    <pps-page v-if="type=='pps'"></pps-page>
    <cyls-page v-if="type=='cyls'"></cyls-page>
    <cysy-page v-if="type=='cysy'"></cysy-page>
    <dcps-page v-if="type=='dcps'"></dcps-page>
    <fwydc-page v-if="type=='fwydc'"></fwydc-page>
    <sxls-page v-if="type=='sxls'"></sxls-page>
    <sxsy-page v-if="type=='sxsy'"></sxsy-page>
    <zxsc-page v-if="type=='zxsc'"></zxsc-page>
    <sxps-page v-if="type=='sxps'"></sxps-page>
    <ccgl-page v-if="type=='ccgl'"></ccgl-page>
    <wls-page v-if="type=='wls'"></wls-page>
    <wlps-page v-if="type=='wlps'"></wlps-page>
    <cxapp-page v-if="type=='cxapp'"></cxapp-page>
    <smdc-page v-if="type=='smdc'"></smdc-page>
    <fxs-page v-if="type=='fxs'"></fxs-page>
    <gysjcjg-page v-if="type=='gysjcjg'"></gysjcjg-page>
    <ppsht-page v-if="type=='ppsht'"></ppsht-page>
    <fxsht-page v-if="type=='fxsht'"></fxsht-page>
    <wmxcx-page v-if="type=='wmxcx'"></wmxcx-page>
    <yzcgl-page v-if="type=='yzcgl'"></yzcgl-page>
    <qdxcx-page v-if="type=='qdxcx'"></qdxcx-page>
    <sznp-page v-show="type=='sznp'"></sznp-page>
    <sznpsjgl-page v-show="type=='sznpsjgl'"></sznpsjgl-page>
    <zhxnm-page v-show="type=='zhxnm'"></zhxnm-page>
    <zxscxcx-page v-show="type=='zxscxcx'"></zxscxcx-page>
    <sycz-page v-show="type=='sycz'"></sycz-page>
    <psapp-page v-show="type=='psapp'"></psapp-page>
    <djcyl-page v-show="type=='djcyl'"></djcyl-page>
    <wsplapp-page v-show="type=='wlpsapp'"></wsplapp-page>
    <hyszhp-page v-show="type=='hysznp'"></hyszhp-page>
  </div>
</template>

<script>
import djPage from './djpt'
import spncpPage from './spncp'
import ppsPage from './pps'
import cylsPage from './cyls'
import cysyPage from './cysy'
import dcpsPage from './dcps'
import fwydcPage from './fwydc'
import sxlsPage from './sxls'
import sxsyPage from './sxsy'
import zxscPage from './zxsc'
import sxpsPage from './sxps'
import ccglPage from './ccgl'
import wlsPage from './wls'
import wlpsPage from './wlps'
import cxappPage from './cxapp'
import smdcPage from './smdc'
import fxsPage from './fxs'
import gysjcjgPage from './gysjcjg'
import ppshtPage from './ppsht'
import fxshtPage from './fxsht'
import wmxcxPage from './wmxcx'
import yzcglPage from './yzcgl'
import qdxcxPage from './qdxcx'
import sznpPage from "./sznp";
import sznpsjglPage from "./sznpsjgl";
import zhxnmPage from "./zhxnm";
import zxscxcxPage from "./zxscxcx";
import syczPage from "./sycz";
import psappPage from "./psapp";
import djcylPage from "./djcyl";
import wsplappPage from "./wsplapp";
import hyszhpPage from "./hyszhp";
export default {
  name:'DetailsOne',
  components:{wmxcxPage,fxshtPage,ppshtPage,gysjcjgPage,fxsPage,smdcPage,djPage,cxappPage,spncpPage,
    ppsPage,cylsPage,cysyPage,dcpsPage,fwydcPage,sxlsPage,sxsyPage,zxscPage,sxpsPage,ccglPage,wlsPage,
    wlpsPage,yzcglPage,qdxcxPage,sznpPage,sznpsjglPage,zhxnmPage,zxscxcxPage,syczPage,psappPage,djcylPage,
    wsplappPage,hyszhpPage
  },
  data(){
    return{
      type:''
    }
  },
  watch:{
    $route(to,form){
      this.type = this.$route.query.type
    }
  },
  created() {
    this.type=this.$route.query.type
  },
}
</script>

<style scoped>

</style>
