<template>
  <div>
    <shopTop></shopTop>
    <div v-show="goDp" class="shop-list">
      <div class="ya315-container">
        <div class="list-nav-brand">
          <router-link :to="{path:'/shop/list'}">全部分类</router-link>
          <template v-if="pathName.length<=1">
             >
            <a>{{data.sonCateName}}</a>
          </template>
        </div>
        <div class="shop-list-content">
          <div class="pro-list">
            <div class="pro-basic">
              <div class="pro-img">
<!--                v-if="100024"-->
                <img width="300" height="300" :src="img" alt="">
                <div style="display: flex;margin-top: 20px;">
                  <div @click="currentTool(item,index)" style="width: 60px;height: 60px;border: 1px solid white;line-height: 53px;cursor: pointer;" :class="current==index?'current':''" v-for="(item,index) in arr" :key="index">
                    <img width="54" height="54" :src="item" alt="">
                  </div>
                </div>
              </div>
              <div class="pro-basic-detail">
                <div class="name">{{data.goodsTitle}}</div>
                <div class="desc">{{data.introduce}}</div>
                <div class="common">
                  <div class="label" style="line-height:39px;">价格</div>
                  <div class="price" v-show="active==index" v-for="(item,index) in data.goodsSpecs" :key="index">￥{{ item.price/100 | price }}</div>
                </div>
                <div class="common" v-show="couPonList.length!=0">
                  <div class="label" style="line-height:39px;">优惠券</div>
                  <div class="coupon" v-show="couPonList.length!=0" v-for="(item,index) in couPonList" :key="index">
                    <div style="width: 50px;height: 20px;border: 1px solid red;text-align: center;color: red;font-size: 12px;line-height: 16px;">优惠券</div>
                    <span style="margin-left: 10px;">{{ item.couponName }}</span>
                    <el-button type="text" style="color: red;margin-left: 10px;">领取</el-button>
                  </div>
                </div>
                <div class="side-line"></div>
                <div class="common">
                  <div class="label">规格</div>
                  <div style="cursor: pointer" v-for="(item,index) in data.goodsSpecs" :key="index" class="sel-list">
                    <div @click="active=index" :class="active==index?'cur':''">{{ item.specName }}</div>
                  </div>
                </div>
                <div class="common">
                  <div class="label">日期单位</div>
                  <div v-for="(item,index) in data.goodsSpecs" :key="index" class="sel-list">
                    <div v-show="active==index" class="cur">
                      <span v-show="item.dateUnit == 1">天</span>
                      <span v-show="item.dateUnit == 2">月</span>
                      <span v-show="item.dateUnit == 3">年</span>
                      <span v-show="item.dateUnit == 4">长期</span>
                    </div>
                  </div>
                </div>
                <div class="common">
                  <div class="label">数量</div>
                  <div>
                    <el-input-number size="small" v-model="num"></el-input-number>
                  </div>
                </div>
                <div class="btn-group">
                  <div style="cursor: pointer" @click="addShop">立即购买</div>
                  <div style="cursor: pointer" @click="addcart">加入购物车</div>
                  <div style="cursor: pointer;">咨询</div>
                </div>
              </div>
            </div>
            <div class="pro-detail">
              <div class="pro-nav">
                <div style="cursor: pointer;" @click="too=0" :class="too==0?'cur':''">产品详情</div>
                <div style="cursor: pointer;" @click="too=1" :class="too==1?'cur':''">使用教程</div>
                <div style="cursor: pointer;" @click="too=2" :class="too==2?'cur':''">售后服务</div>
              </div>
              <div class="content">
                <div class="detail" v-show="too==0">
                  <div>
                    <video v-show="data.goodsVideo != ''" width="100%" height="300" controls="controls">
                      <source :src="data.goodsVideo" type="video/mp4">
                    </video>
                  </div>
                  <div v-html="data.goodsDetail"></div>
                </div>
                <div v-show="too==1">
                  <div v-for="(item,index) in data.guide?JSON.parse(data.guide):'10'" style="display: flex;justify-content: space-between;">
                    <div style="display: flex;align-items: center">
                      <img style="width: 25px;" src="../../assets/img/file.png" alt="">
                      <span style="margin-left: 10px;">{{ item.name }}</span>
                    </div>
                    <a class="upload" :href="item.path">下载</a>
                  </div>
                </div>
                <div v-show="too==2">
                  <div v-html="data.support"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="pro-recommend">
            <div class="pro-recommend-title">{{ data.storeName }}</div>
            <div class="pro-recommend-p">资质信息： 查看</div>
            <div class="pro-recommend-p">客服电话： {{ data.phone }}</div>
            <div class="pro-recommend-p">客服邮箱： {{ data.mailbox }}</div>
            <div class="pro-recommend-p">在线时间： 工作日 {{ JSON.parse(data.serviceTime)[0] }}-{{ JSON.parse(data.serviceTime)[1] }}</div>
            <div class="pro-recommend-btn" style="cursor: pointer;" @click="godianpu">进入店铺</div>
          </div>
        </div>
      </div>
    </div>
    <div v-show="!goDp" class="shop-list">
      <div class="ya315-container">
        <div style="width: 1200px;height: 300px;">
          <img style="margin-top: 20px;" width="1200" height="300" :src="storeData.storeBanner" alt="">
        </div>
        <div class="list-nav-brand">
          <div class="header">
            <div><img width="300" :src="storeData.storeLogo" alt=""></div>
            <div>
              <div style="font-size: 18px;height: 40px;">{{ storeData.storeName }}</div>
              <div style="display: flex;justify-content: space-between;width: 450px;align-items: center;">
                <div style="font-size: 14px;height: 40px;"><span>客服电话:</span><span>{{ storeData.phone }}</span></div>
                <div style="font-size: 14px;height: 40px;"><span>电子邮件:</span><span>{{ storeData.mailbox }}</span></div>
              </div>
              <div style="font-size: 14px;height: 40px;"><span>服务时间:</span><span>周一至周五  08:30~18:00</span></div>
            </div>
            <div style="width: 350px;height: 200px;overflow: auto;padding: 10px;"></div>
          </div>
          <div class="storeCoupon">
              <div style="width: 380px;display: flex;align-items: center;justify-content: space-between;background: white;padding: 10px 20px;margin-top: 10px;" v-for="(item,index) in storeCouponList" :key="index">
                <div style="width: 110px;height: 20px;border: 1px solid red;color: red;
                font-size: 12px;line-height: 16px;text-align: center;">{{ item.couponType==1?'通用':'商品' }} | 优惠券</div>
                <span style="margin-left: 10px;font-size: 14px;">{{ item.couponName }}</span>
                <el-button type="text" style="color: red;margin-left: 10px;">领取</el-button>
              </div>
          </div>
          <div class="title">
            <el-tabs v-model="activeName" style="background: white;padding: 0 25px;">
              <el-tab-pane label="全部商品" name="0"/>
              <el-tab-pane label="企业介绍" name="1"/>
            </el-tabs>
          </div>
          <div class="allShop" v-show="activeName == 0">
            <div class="priceSort">
              <div :class="activeIndex == 0?'active':''">默认排序</div>
              <div>上架时间<i class="el-icon-d-caret"></i></div>
              <div>价格<i class="el-icon-d-caret"></i></div>
            </div>
            <div class="shopList">
              <div v-for="(item,index) in shopList" :key="index">
                <div style="display: flex;margin-top: 20px;">
                  <div style="width: 64px;height: 64px;margin-left: 20px;">
                    <img width="64" height="64" :src="JSON.parse(item.goodsImgs)?JSON.parse(item.goodsImgs)[0]:''" alt="">
                  </div>
                  <div style="margin-left: 20px;">
                    <p style="width: 200px;height: 64px;font-weight:bold;line-height: 64px;display: -webkit-box;-webkit-box-orient: vertical;
	                    -webkit-line-clamp: 1;overflow: hidden;">{{ item.goodsTitle }}</p>
                  </div>
                </div>
                <div>
                    <p style="font-size: 14px;padding: 20px;height: 80px;display: -webkit-box;
	-webkit-box-orient: vertical;
	-webkit-line-clamp: 3;
	overflow: hidden;">{{ item.introduce }}</p>
                    <p style="font-size: 16px;padding: 10px 20px;font-weight: bold;">{{ item.storeName }}</p>
                </div>
                <div style="display: flex;align-items: center;justify-content: space-between;height: 100px;padding: 0 20px;">
                  <div style="font-weight: bold;font-size: 25px;">￥{{ item.price/100 | price }}</div>
                  <div style="cursor: pointer;font-size: 15px;">
                    <el-button style="margin-right: 20px;"  @click="goCart(item)" size="small" type="primary">立即购买</el-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%;height: 320px;background: white;" v-show="activeName == 1">
            <div style="width: 90%;height: 170px;margin: auto;padding-top: 20px;line-height: 30px;color: #52565b;">{{ storeData.introduce }}</div>
          </div>
        </div>
      </div>
    </div>
<!--    <shopFoot></shopFoot>-->
  </div>
</template>

<script>
  import shopTop from '@/components/Shop/Top.vue'
  import shopFoot from '@/components/Shop/Foot.vue'
  import {comCoupon, storeCoupon} from "../../utils/api";
  // const PROLIST = require('../../../public/data/proList.json');
  export default {
    name: "list",
    data(){
      return {
        img:"",
        pathName:[],
        data:{},
        // proList:PROLIST,
        goDp:true,
        activeName:0,
        activeIndex:0,
        shopList:[],
        list:JSON.parse(localStorage.getItem("cartList"))?JSON.parse(localStorage.getItem("cartList")):[],
        active:0,
        current:0,
        arr:[],
        too:0,
        num:1,
        storeData:{},
        shopData:[],
        couPonList:[],
        storeCouponList:[],
      }
    },
    components:{ shopTop, shopFoot },
    mounted() {
      this.init()
    },
    filters:{
      price(val){
        if(!isNaN(val)){return val.toFixed(2)}else{return val}
      },
    },
    methods:{
      before(){},
      couponList(){
        this.http.comCoupon({goodsId:this.$route.query.id}).then(res=>{
          if(res.code == "1"){
            this.couPonList = res.data
          }
        })
      },
      init(){
        this.http.getGoodsOne('?id=' + this.$route.query.id).then(res=>{
            console.log(res.data)
          this.data = res.data
          let arr = JSON.parse(res.data.goodsImgs)
          this.img = arr[0]
          arr.forEach((item,index)=>{
            this.arr.push(item)
          })
        })
        this.http.cartNum().then(res=>{
          if(res.code == "1"){
            this.$root.Bus.$emit('toggle', res.data)
          }
        })
        this.http.getAccount().then(res=>{
          if(res.code == "1"){
            this.$root.Bus.$emit('account', res.content)
          }
        })
        this.couponList()
      },
      goCart(item){
        this.arr = []
        this.data = {}
        this.data.guide = ""
        this.$router.push({
          path:'/shop/detail',
          query:{id:item.id,pathName:this.$route.query.pathName}
        })
        this.init()
        this.goDp = true
      },
      currentTool(item,index){
        this.img = item
        this.current = index
      },
      addShop(){
        this.http.getAccount().then(msg=>{
          if(msg.code == "1"){
            if(msg.content.realNameState != 0){
              this.http.addrList().then(msg=>{
                if(msg.code == "1"){
                  this.http.getGoodsOne('?id=' + this.$route.query.id).then(res=>{
                    if(res.code == "1"){
                      let data = [
                        {
                          storeName:res.data.storeName,
                          sellerId:res.data.sellerId,
                          shops:[
                            {
                              sellerId:res.data.sellerId,
                              dateNum: this.data.goodsSpecs[this.active].dateNum,
                              dateUnit: this.data.goodsSpecs[this.active].dateUnit,
                              goodsId:this.data.id,
                              goodsImgs: res.data.goodsImgs,
                              goodsTitle: res.data.goodsTitle,
                              id: res.data.id,
                              isOnline: res.data.isOnline,
                              price: this.data.goodsSpecs[this.active].price,
                              specId:this.data.goodsSpecs[this.active].id,
                              specName: this.data.goodsSpecs[this.active].specName,
                              quantity:this.num
                            }
                          ],
                        }
                      ]
                      localStorage.removeItem("order")
                      localStorage.setItem("order",JSON.stringify(data))
                      localStorage.setItem("state","111")
                      this.$router.push({path:"/confirmOrder"})
                    }
                  })
                }
              })
            }else{
              this.$confirm('您还未实名认证，是否前去实名认证?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
              }).then(() => {
              //  前往实名认证页面
                window.open(this.baseUrl.buyerApiUrl + '/goods/auth')
              }).catch(() => {});
            }
          }else{
            localStorage.setItem("fullPath",this.$route.fullPath)
            this.$router.push({
              path:"/login"
            })
          }
        })
      },
      addcart(){
        let obj = {
          goodsId:this.data.id,
          specId:this.data.goodsSpecs[this.active].id,
          quantity:this.num
        }
        this.http.addCart(obj).then(res=>{
          if(res.code == "1"){
            this.$message({message:"加入购物车成功",type:"success"})
            this.num = 1
            this.http.cartNum().then(res=>{
              if(res.code == "1"){
                this.$root.Bus.$emit('toggle', res.data)
              }
            })
          }else{
            localStorage.setItem("fullPath",this.$route.fullPath)
            this.$router.push({
              path:"/login"
            })
          }
        })
      },
      godianpu(){
        this.http.currentStore(`?id=${this.data.onlineId}`).then(res=>{
          if(res.code == "1"){
            console.log(res.data)
            this.storeData = res.data
            this.storeData.storeBanner = res.data.storeBanner
            this.storeData.storeLogo = res.data.storeLogo
            //店铺商品列表
            this.http.getGoodsList('?onlineInfoId='+res.data.id).then(msg=>{
                if(msg.code == "1"){
                  console.log(msg.data.data)
                  this.shopList = msg.data
                  this.goDp = false
                  this.shopList.forEach((item,index)=>{
                    item.logo = this.imageUrl + item.logo
                  })
                }
            })
            //店铺优惠券列表
            this.http.storeCoupon({accountId:res.data.accountId}).then(res=>{
              if(res.code == "1"){
                this.storeCouponList = res.data
              }
            })
            // getGoodsList
          }
        })
        // console.log(this.data.onlineId)
      },
    }
  }
</script>

<style scoped>
  .shop-list{background:#F5F7FD;box-shadow:inset 0px 15px 10px -15px rgba(38, 144, 253,.1);}
  .list-nav-brand{padding-top:36px;margin-left:8px;font-size: 18px;margin-bottom:30px;color:#52565B;}
  .list-nav-brand>a{margin:0 16px;color:#52565B;}
  .shop-list-content{display: flex;justify-content: space-between;}
  .pro-list{width:944px;}
  .pro-basic{height:551px;background:#ffffff;display: flex;margin-bottom:36px;}
  .pro-img{width:400px;height:400px;margin-left:30px;margin-top:24px;text-align: center;vertical-align: middle;}
  .pro-basic-detail{margin-top:30px;margin-left:32px;}
  .pro-basic-detail .name{color:#121A25;font-size: 20px;}
  .pro-basic-detail .desc{width: 550px;color:#858A90;font-size: 14px;margin-top:12px;}
  .pro-basic-detail .common{font-size: 16px;color:#858A90;margin-top:20px;display: flex;}
  .common>.label{width:90px;}
  .common>.price{color:#FE5E54;font-size: 26px;}
  .common>.coupon{line-height: 35px;color: black;font-size: 15px;display: flex;align-items: center}
  .side-line{width:800px;border-top:1px solid #E8E9EB;margin-top:30px;}
  .sel-list{display: flex;}
  .sel-list>div{margin-right:16px;border:1px solid #E8E9EB;color:#52565B;text-align: center;padding: 5px 10px;}
  .sel-list>div.cur{color:#1890FF;border-color:#1890FF;}
  .btn-group{margin-top:39px;}
  .btn-group>div{width:114px;height:40px;border-radius: 20px;text-align: center;border:1px solid #1890FF;background:#1890FF;color:#ffffff;margin-right:27px;line-height: 40px;box-sizing: content-box;}
  .btn-group>div:last-child{color:#1890FF;background:#ffffff;}

  .pro-detail{background:#ffffff;margin-bottom:150px;}
  .pro-nav{height:65px;display: flex;line-height: 65px;border-bottom:1px solid #E8E9EB;}
  .pro-nav>div{width:136px;text-align: center;color:#52565B;font-size: 16px;}
  .pro-nav>div.cur{color:#1890FF;}
  .pro-detail .content{padding:70px 60px;color:#52565B;font-size: 16px;}
  .pro-detail .content ::v-deep img{
    width: 800px;
    display: block;
    margin: 0 auto;
  }
  .content h3{text-align: center;}

  .pro-recommend{width:230px;background:#ffffff;height:301px;}
  .pro-recommend-title{height:70px;line-height: 70px;color:#858A90;font-size: 16px;border-bottom:1px solid #E8E9EB;width:200px;margin: 0 auto;}
  .pro-recommend-p{color:#858A90;font-size: 12px;margin:22px 10px 0 16px;text-align: left;}
  .pro-recommend-btn{margin:22px 16px 0;line-height: 50px;border-top:1px solid #E8E9EB;font-size: 12px;color:#4983FF;text-align: center;}
  .header{
    width: 100%;
    height: 200px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
  .title{
    width: 100%;
    margin-top: 20px;
  }
  .allShop{
    width: 100%;
    padding: 20px;
    background: white;
    margin-top: 20px;
  }
  .priceSort{
    width: 100%;
    border-bottom: 1px solid #bbb;
    height: 50px;
    display: flex;
    align-items: center;
  }
  .priceSort>div{
    width: 120px;
    height: 50px;
    text-align: center;
    font-size: 15px;
    line-height: 50px;
    cursor: pointer;
  }
  .active{
    color: #2690FD;
  }
  .shopList{
    display: flex;
    flex-wrap: wrap;
    padding: 0 20px;
  }
  .shopList>div{
    width: 350px;
    border: 1px solid #e5e5e5;
    margin-top: 40px;
    margin-left: 20px;
  }
  .current{
    border: 1px solid #00BAFF !important;
  }
  .detail img{
    width: 800px !important;
    height: 800px !important;
  }
  .upload:hover{
    color: red;
  }
  .storeCoupon{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
</style>
